import React from 'react';
import { connect } from 'react-redux';
import MultiMarkers from '../MultiMarkers';
import { busStopIcon } from '../../resources';

const MarkersBusStops = props => {
    const {busStops, showMarkers, name} = props
    if(!showMarkers[name]) {return null}
    
    const locations = busStops.map(item => {

        return({
            coords: [item.lat, item.lon],
            tooltip: item.stop,
            popup: <p>ATCO Code: {item.atcocode}</p>
            
        })
    })
    
    return(
        <MultiMarkers 
            data={locations} 
            icon={busStopIcon}
        />
    )
}

const mapStateToProps = ({showMarkers, busStops}) => {
    return ({showMarkers, busStops})
}

export default connect(mapStateToProps, {})(MarkersBusStops)
import React from 'react';
import { 
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Legend,
    Tooltip 
} from 'recharts';
import { 
  convertToPercentageDecimal, 
  invertChartColours, 
} from '../../functions';
import { chartColours } from '../../constants';

export const DoublePieChart = props => {
  const {labelCallback, data: {ward, region, country, district}, showFull} = props
  const comparator = region ? region : country
  const locationName = ward ? ward : district
  const colours = chartColours[0]
  const dimensions = {
    innerRadius: showFull ? 125 : 80,
    outerRadius: showFull ? 165 : 125,
    legendMargin: showFull ? -70 : -100
  }
  
  
  const RADIAN = Math.PI / 180;

  const innerLabels = labelProps => {  
    const {cx, cy, midAngle, innerRadius, outerRadius, payload} = labelProps
    const data = payload.payload
    
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 10;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill={invertChartColours(payload.fill)} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {convertToPercentageDecimal(data.value)}
      </text>
    )
  }

  const outerLabels = labelProps => {  
    const {cx, cy, midAngle, innerRadius, outerRadius, payload} = labelProps
    const data = payload.payload
    
    const radius = innerRadius + (outerRadius - innerRadius) * 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) - 25;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text 
        x={x} 
        y={y} 
        fill={payload.fill} 
        textAnchor="left" 
        dominantBaseline="central">
        {convertToPercentageDecimal(data.value)}
      </text>
    )
  }

  const CustomTooltip = props => {
    const {active, payload} = props
    if (active && payload && payload.length) {
      const location = payload[0].payload.name[0].location
      const subject = labelCallback(payload[0].payload.payload.subject)
      const value = convertToPercentageDecimal(payload[0].payload.payload.value)

      return (
      <div className="custom_tooltip" >
          <p>{location}</p>
          <p className="label">{subject}: {value}</p>
      </div>
      )}}

  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart margin={{top: 0, right: 30, left: 30, bottom: 0}}>
        <Pie 
          data={locationName} 
          dataKey="value" 
          cx="50%" 
          cy="50%" 
          outerRadius={dimensions.innerRadius} 
          fill="#8884d8" 
          labelLine={false}
          label={innerLabels}
          startAngle={90}
          endAngle={-270}
          name={locationName}
          >
            {locationName.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colours[index % colours.length]} />
          ))}
          </Pie>
        <Pie 
          data={comparator} 
          dataKey="value" 
          cx="50%" 
          cy="50%" 
          innerRadius={dimensions.innerRadius + 10} 
          outerRadius={dimensions.outerRadius}
          fill="#8884d8" 
          label={outerLabels}
          startAngle={90}
          endAngle={-270}
          nameKey="subject"
          name={comparator}
          >
            {locationName.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colours[index % colours.length]} />
          ))}
          </Pie>
          <Tooltip content={<CustomTooltip/>} position="left"/>
          <Legend
            payload={locationName.map(
              (item, index) => ({
                id: item.subject,
                type: "square",
                value: labelCallback(item.subject),
                color: colours[index]
              }))
            }
            wrapperStyle={{position: 'relative', marginTop: dimensions.legendMargin}}
          />
      </PieChart>
  </ResponsiveContainer>

  )
}
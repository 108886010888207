import React from 'react';
import { motion } from 'framer-motion';
import history from '../history';

export const MainMenu = props => {

    const onMenuSelect = link => {
        history.push(`/${link}`)
        props.showMenu(false)
    }

    return (
        <motion.div 
            className="custom_menu_container ui container" 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay: 0.5}}>
            <div className="custom_menu_content">
                <div className="custom_menu_item">
                    <h5 
                        onClick={() => onMenuSelect("about")}
                        className="ui right aligned custom_menu_text clickable_text_dark"
                        >About
                    </h5>
                </div>
                <div className="custom_menu_item">
                    <h5 
                        onClick={() => onMenuSelect("contact")}
                        className="ui right aligned custom_menu_text clickable_text_dark"
                        >
                        Contact us
                        </h5>
                </div>
                <div className="custom_menu_item">
                    <h5 
                        onClick={() => onMenuSelect("methodology")}
                        className="ui right aligned custom_menu_text clickable_text_dark"
                        >
                        Our scoring methodology
                    </h5>
                </div>
                <div className="custom_menu_item">
                    <h5 
                        onClick={() => onMenuSelect("sources")}
                        className="ui right aligned custom_menu_text clickable_text_dark"
                        >
                        Data sources
                        </h5>
                </div>
            </div>
        </motion.div>
    )
}

export const NavBuffer = () => {
    return (
        <div className="custom_nav_buffer"/>
    )
}
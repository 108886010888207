import qcApi, { ipGeolocationApi } from '../apis';
import {
    SET_VIEWPORT, 
    SET_SHOW_MOBILE_MENU,
    SET_SEARCH_TERM,
    SET_SEARCH_RESULTS,
    RETRIEVE_SEARCH_RESULTS,
    SET_AREA_DATA,
    SET_SHOW_DETAILS,
    SET_HOUSING_DATA,
    SET_NURSERIES_DATA,
    SET_SHOW_MARKERS,
    SET_PARKS_DATA,
    SET_SCHOOLS_DATA,
    SET_SCHOOL_GROUP,
    SET_BUS_STOPS,
    SET_TRAIN_STATIONS, 
    SET_CHARGER_STATIONS,
    SET_COMMUTING_DATA,
    SET_AFFLUENCE_DATA,
    SET_DEMOGRAPHICS_DATA,
    SET_DIVERSITY_DATA,
    SET_TENURE_AND_HEALTH_DATA,
    SET_WORKER_DATA,
    SET_CRIME_DATA,
    SET_SHOW_MODAL,
    SET_MODAL_TYPE,
    SET_LOG_STATUS,
    SET_LOGIN_MESSAGE,
    SET_SIGNUP_MESSAGE,
    SET_REQUEST_PASSWORD_MODAL,
    SET_RESET_PASSWORD_MODAL,
    SET_MODAL_HEADER,
    SET_TEMPORARY_CREDENTIALS,
    SET_LOADING_TIMER,
    SET_NO_RESULTS,
    SET_CONTENT_HEIGHT,
    SET_MESSAGE_MODAL,
    SET_MESSAGE_MODAL_CONTENT,
    SET_CHECK_AUTH,
    SET_AREA_VIEW_ID,
    SET_FINGERPRINT,
    SET_CLIENT_COUNTRY,
    CLEAR_SEARCH_RESULTS,
} from './types';
import { ipGeolocationKey } from '../constants';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { convertSectionNameToField } from '../functions/labels';

export const setFingerprint = () => async dispatch => {

    const fp = await FingerprintJS.load()
    const result = await fp.get()

    dispatch({
        type: SET_FINGERPRINT,
        payload: result.visitorId
    })
}

export const setViewport = dimenions => ({
    type: SET_VIEWPORT, 
    payload: dimenions
})

export const setShowMobileMenu = bool => ({
    type: SET_SHOW_MOBILE_MENU,
    payload: bool
})

export const setSearchTerm = term => ({
    type: SET_SEARCH_TERM,
    payload: term
})

export const setSearchResults = (searchTerm, searchId, client) => async dispatch => {
    
    const response = await qcApi.post(`/search/${searchTerm}`, {search_id: searchId, client})

    dispatch({
        type: SET_SEARCH_RESULTS,
        payload: response.data
    })
}

export const clearSearchResults = () => ({
        type: CLEAR_SEARCH_RESULTS,

    })

export const retrieveSearchResults = searchTerm => async dispatch => {

    const response = await qcApi.get(`/search/${searchTerm}`)
    
    dispatch({
        type: RETRIEVE_SEARCH_RESULTS,
        payload: response.data
    })
}

export const setAreaData = (areaId, pageViewId, country, client) => async dispatch => {
    
    const viewId = pageViewId ? pageViewId : 0
    const response = await qcApi.get(`/area/${areaId}`, {params : {
        page_view_id: viewId,
        country, 
        client
    }})

    dispatch({
        type: SET_AREA_DATA,
        payload: response.data
    })
}

export const setShowDetails = (section, pageViewId) => {

    const key = Object.keys(section)[0]
    const field = convertSectionNameToField(key)
    // get pageViewId
    qcApi.patch("/area-view", {field, page_view_id: pageViewId})

    const sections = {
        housing: false,
        amentiesAndServices: false,
        schools: false,
        gettingAround: false,
        affluence: false,
        demographics: false,
        workerProfiles: false,
        tenureAndHealth: false,
        diversity: false,
        crime: false
    }

    return ({
        type: SET_SHOW_DETAILS,
        payload: {...sections, ...section}
    })
}


export const setHousingData = (areaId, localAuth, name) => async dispatch => {

    const response = await qcApi.get(`/housing/${areaId}`, {params : {
        local_authority_code: localAuth,
        ward_name: name
    }})
    
    dispatch({
        type: SET_HOUSING_DATA,
        payload: response.data
    })
}

export const setNurseriesData = areaId => async dispatch => {
    const response = await qcApi.get(`/nurseries/${areaId}`)

    dispatch({
        type: SET_NURSERIES_DATA,
        payload: response.data.nurseries
    })
}

export const setShowMarkers = object => {

    const updatedMarkers = object

    return({
        type: SET_SHOW_MARKERS,
        payload: updatedMarkers
    })
}

export const setParksData = areaId => async dispatch => {
    const response = await qcApi.get(`parks/${areaId}`)

    dispatch({
        type: SET_PARKS_DATA,
        payload: response.data.parks
    })
}

export const setSchoolsData = areaId => async dispatch => {
    const response = await qcApi.get(`schools/${areaId}`)

    dispatch({
        type: SET_SCHOOLS_DATA,
        payload: response.data
    })
}

export const setSchoolGroup = schoolGroup => {

    const schoolSelect = {primary: true, secondary: true, value: "all"}

    if (schoolGroup === "primary") {
        schoolSelect.secondary = false
        schoolSelect.value = schoolGroup
    } else if (schoolGroup === "secondary") {
        schoolSelect.primary = false
        schoolSelect.value = schoolGroup
    }

    return({
        type: SET_SCHOOL_GROUP,
        payload: schoolSelect
    })
}

export const setBusStops = areaId => async dispatch => {
    const response = await qcApi.get(`busstops/${areaId}`)

    dispatch({
        type: SET_BUS_STOPS,
        payload: response.data.bus_stops
    })
}

export const setTrainStations = areaId => async dispatch => {
    const response = await qcApi.get(`trainstations/${areaId}`)
    
    dispatch({
        type: SET_TRAIN_STATIONS,
        payload: response.data.stations
    })
}

export const setChargerStations = areaId => async dispatch => {
    const response = await qcApi.get(`chargers/${areaId}`)

    dispatch({
        type: SET_CHARGER_STATIONS,
        payload: response.data.chargers
    })
}

export const setCommutingData = code => async dispatch => {
    const response = await qcApi.get(`commuting/${code}`)

    dispatch({
        type: SET_COMMUTING_DATA,
        payload: response.data
    })
}

export const setAffluenceData = code => async dispatch => {
    const response = await qcApi.get(`affluence/${code}`)

    dispatch({
        type: SET_AFFLUENCE_DATA,
        payload: response.data
    })
}

export const setDemographicsData = code => async dispatch => {
    const response = await qcApi.get(`demographics/${code}`)

    dispatch({
        type: SET_DEMOGRAPHICS_DATA,
        payload: response.data
    })
}
export const setDiversityData = code => async dispatch => {
    const response = await qcApi.get(`diversity/${code}`)

    dispatch({
        type: SET_DIVERSITY_DATA,
        payload: response.data
    })
}

export const setTenureAndHealthData = code => async dispatch => {
    const response = await qcApi.get(`tenure-health/${code}`)

    dispatch({
        type: SET_TENURE_AND_HEALTH_DATA,
        payload: response.data
    })
}

export const setWorkerData = code => async dispatch => {
    const response = await qcApi.get(`worker-profiles/${code}`)

    dispatch({
        type: SET_WORKER_DATA,
        payload: response.data
    })
}

export const setCrimeData = code => async dispatch => {
    const response = await qcApi.get(`crime/${code}`)

    dispatch({
        type: SET_CRIME_DATA,
        payload: response.data
    })
}

export const setShowModal = bool => {

    return({
        type: SET_SHOW_MODAL,
        payload: bool
    })
}

export const setModalType = type => {

    return({
        type: SET_MODAL_TYPE,
        payload: type
    })

}

export const setLogStatus = status => {

    return({
        type: SET_LOG_STATUS,
        payload: status
    })
}

export const setLoginMessage = message => {

    return({
        type: SET_LOGIN_MESSAGE,
        payload: message
    })
}

export const setSignupMessage = message => {

    return({
        type: SET_SIGNUP_MESSAGE,
        payload: message
    })
}

export const setRequestPasswordModal = text => {

    return({
        type: SET_REQUEST_PASSWORD_MODAL,
        payload: text
    })
}

export const setResetPasswordModal = text => {

    return({
        type: SET_RESET_PASSWORD_MODAL,
        payload: text
    })
}
export const setModalHeader = text => {

    return({
        type: SET_MODAL_HEADER,
        payload: text
    })
}

export const setTemporaryCredentials = creds => {

    return({
        type: SET_TEMPORARY_CREDENTIALS,
        payload: creds
    })
}

export const setLoadingTimer = timer => {

    return({
        type: SET_LOADING_TIMER,
        payload: timer
    })
}

export const setNoData = status => {

    return({
        type: SET_NO_RESULTS,
        payload: status
    })
}

export const setContentHeight = status => {

    return({
        type: SET_CONTENT_HEIGHT,
        payload: status
    })
}

export const setMessageModal = bool => {

    return({
        type: SET_MESSAGE_MODAL,
        payload: bool
    })
}

export const setMessageModalContent = text => {
    return({
        type: SET_MESSAGE_MODAL_CONTENT,
        payload: text
    })
}

export const setCheckAuth = bool => {
    return ({
        type: SET_CHECK_AUTH,
        payload: bool
    })
}

export const setAreaViewId = (search_id, search_term, area, client, country) => async dispatch => {

    const response = await qcApi.post("/area-view", {
        search_id,
        search_term,
        area,
        country,
        client
    })

    const viewId = response.data.view_id

    dispatch({
        type: SET_AREA_VIEW_ID,
        payload: viewId
    })
}

export const setClientCountry = () => async dispatch => {

    const locationData = await ipGeolocationApi.get("", {
        params: {apiKey: ipGeolocationKey}
    })
    const country = locationData.data.country_code3

    dispatch({
        type: SET_CLIENT_COUNTRY,
        payload: country
    })


}


import React from 'react';
import { metricLabels } from '../../functions/labels';
import { generateLabel, metricDescription } from '../../functions/metrics';
import { smallScreenWidth } from '../../constants';

const MetricScores = ({areaMetrics, screenWidth, ratingExemption}) => {

    const metricColumnWidths = screenWidth > smallScreenWidth
    ? "eight"
    : "sixteen"
    
    return Object.entries(areaMetrics).map((item, index) => {
    const label = metricLabels(item[0])
    const score = (item[1] * 10).toFixed(1)
    const exempt = ratingExemption.find(exclItem => exclItem === item[0])
    const labelStyle = exempt
    ? "ui black horizontal label"
    : generateLabel(score)

    const metricField = item[0].includes("score_") 
    
    if(metricField && item[1] && item[1] < 1.001) {
        
        return (
            <div className={`${metricColumnWidths} wide column`} key={index}>
                <div>
                    <div className="ui grid">
                        <div className="twelve wide column">
                            <div className="ui tiny header">{label}</div>
                            {metricDescription(item[0], score, areaMetrics)}
                        </div>
                        <div className="four wide column">
                            <div className="ui tiny header right floated">
                                <span className={`ui header ${labelStyle}`}>
                                        {score}
                                </span>
                                <hr/>
                                <div className="custom_center_text">10</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (metricField && (!item[1] || item[1] === 1.001)) {
        return (
            <div className={`${metricColumnWidths} wide column`} key={index}>
            <div>
                <span className="ui tiny header">{label}: &nbsp; &nbsp; </span>
                <span className={`ui header ui`}>No data available</span>
            </div>
        </div>
        )
    } else return null
    
})}

export default MetricScores

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import history from '../history';

const Footer = ({contentHeight, screenHeight}) => {
    
    const [footerMargin, setFooterMargin] = useState(0)

    useEffect(() => {
        if(window.location.pathname === "/" || contentHeight > 600) {
            setFooterMargin(0)
        } else if(window.location.pathname === "/about") {
            setFooterMargin(0)
        } else if(window.location.pathname === "/contact") {
            setFooterMargin(0)
        } else if(window.location.pathname === "/privacy") {
            setFooterMargin(0)
        } else if(window.location.pathname === "/terms") {
            setFooterMargin(0)
        } else if(window.location.pathname === "/sources") {
            setFooterMargin(0)
        } else if(window.location.pathname === "/methodology") {
            setFooterMargin(0)
        } else if(window.location.pathname === "/collaborate") {
            setFooterMargin(0)
        } else {
            setFooterMargin(600)
        } 
    }, [contentHeight])
    
    return (
        <>
        <div className="custom_footer_container" style={{marginTop: `${footerMargin}px`}}>
        <div className="ui container">
            <div className="ui basic segment">
            <div className="ui center aligned grid">
                <div className="three column row">
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/about")}>
                        About
                    </div>
                    <div className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/sources")}>
                        Data sources
                    </div>
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/terms")}>
                        Terms of use
                    </div>
                </div>
                <div className="three column row">
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/contact")}>
                        Contact us
                    </div>
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/methodology")}>
                         Scoring methodology
                    </div>
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/legal")}>
                        Legal
                    </div>
                </div>
                <div className="three column row">
                    <div className="column custom_footer_text"></div>
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/collaborate")}>
                        Collaborate
                    </div>
                    <div 
                        className="column custom_footer_text clickable_text"
                        onClick={() => history.push("/privacy")}>
                        Privacy
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            </div>
        </div>
        </div>
        <div className="">
            <div className="custom_copyright">
                <p style={{marginTop: "auto"}}>
                Copyright 2023 - Hoodchecker Limited
                </p>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = ({contentHeight, screenHeight}) => ({contentHeight, screenHeight})

export default connect(mapStateToProps)(Footer)
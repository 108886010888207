import React from "react";
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Navigation from './components/Navigation';
import Home from './components/Home';
import SearchResults from './components/SearchResults';
import AreaBio from './components/AreaBio';
import Modal from './components/Modal';
import Footer from './components/Footer';
import About from './subcomponents/info/About';
import Contact from './subcomponents/info/Contact';
import Privacy from './subcomponents/info/Privacy';
import Terms from './subcomponents/info/Terms';
import Sources from './subcomponents/info/Sources';
import Methodology from './subcomponents/info/Methodology';
import Collaborate from './subcomponents/info/Collaborate';


const AnimatedRoutes = () => {

    const location = useLocation()
    
    return (
        <AnimatePresence >
            <Navigation key={1}/>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" exact element={<Home />}/>
                    <Route path="/search/:searchTerm" element={<SearchResults />}/>
                    <Route path="/areabio/:areaid" element={<AreaBio />}/>
                    <Route path="/login" element={<Modal />}/>
                    <Route path="/signup" element={<Modal />}/>
                    <Route path="/reset" element={<Modal />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/message" element={<Modal />}/>
                    <Route path="/privacy" element={<Privacy />}/>
                    <Route path="/terms" element={<Terms />}/>
                    <Route path="/legal" element={<Terms />}/>
                    <Route path="/sources" element={<Sources />}/>
                    <Route path="/methodology" element={<Methodology />}/>
                    <Route path="/collaborate" element={<Collaborate />}/>
                    <Route path="*" element={<Navigate replace to="/" />}/>
                </Routes>
            <Footer key={2}/>
        </AnimatePresence>
)}

const mapStateToProps = ({screenHeight}) => {
    return ({screenHeight})
}

export default connect(mapStateToProps)(AnimatedRoutes)
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { mobileScreenWidth, tabletScreenWidth, mediumScreenWidth } from "../constants";
import { setShowDetails, setDiversityData, setNoData, setLoadingTimer } from "../actions";
import { WaterfallChart } from "./charts/WaterfallChart";
import { DoublePieChart } from "./charts/DoublePieChart";
import { 
  ethnicLabels, 
  englishLanguageLabels, 
  languageLabels 
} from "../functions/labels";
import { ChartLoader } from './elements/general'

const DiversityCharts = (props) => {
  const {
    data,
    screenWidth,
    locations: { ward, country, district, region },
  } = props;

  const { english_data, ethnicity_data, language_data } = data;

  if (!english_data || !ethnicity_data || !language_data) {
    return (
      <div className="ui basic segment">
        <ChartLoader/>
      </div>
    )
  }
  const locationName = ward ? ward : district


  const colWidth = screenWidth < tabletScreenWidth ? "sixteen" : "eight";
  const showFull = screenWidth > mediumScreenWidth;
  const topLabel = showFull ? "15%" : "25%"
  
  return (
    <>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">
          {`Ethnic mix`}{" "}
        </div>
        <DoublePieChart 
          data={ethnicity_data} 
          labelCallback={ethnicLabels} 
          showFull={showFull}/>
        <div className="inner_pie_label">{locationName}</div>
        <div className="outer_pie_label" style={{top: topLabel}}>{region}</div>
      </div>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">English a a first language</div>
        <DoublePieChart
          data={english_data}
          labelCallback={englishLanguageLabels}
          showFull={showFull}
        />
        <div className="inner_pie_label">{locationName}</div>
        <div className="outer_pie_label" style={{top: topLabel}}>
            {region}
        </div>
      </div>
      <div className={`sixteen wide column main_chart_container`}>
        <div className="ui small header">Top languages spoken</div>
        <WaterfallChart 
          data={language_data} 
          screenWidth={screenWidth} 
          labelCallback={languageLabels}/>
      </div>
    </>
  );
};

const SectionDiversity = (props) => {
  const {
    loadingTimer,
    screenWidth,
    showSection,
    diversityData,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country, 
      district_code,
      local_authorities 
    },
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({diversity: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({diversity: false})
        clearTimeout(loadingTimer)
    })
  }, [ward, district_code])


  if(!ward && !district_code) {return (
    <div className="ui basic segment">
     <ChartLoader/>
    </div>
  )}

  const {data_status} = diversityData
  const focused = showSection.diversity;
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };

  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ diversity: false });
    } else {
      props.setShowDetails({ diversity: true }, pageViewId);
      if(!data_status) {props.setDiversityData(locationId)};
    }
  };
  
  return (
    <div className="ui violet segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">Diversity</div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <DiversityCharts
            data={diversityData}
            locations={locations}
            screenWidth={screenWidth}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  diversityData,
  areaMetaData,
  noData,
  loadingTimer,
  pageViewId
}) => ({ 
  screenWidth, 
  showSection, 
  diversityData, 
  areaMetaData, 
  noData, 
  loadingTimer,
  pageViewId 
});

export default connect(mapStateToProps, { setShowDetails, setDiversityData, setNoData, setLoadingTimer })(
  SectionDiversity
);

import {SET_LOG_STATUS, SET_CHECK_AUTH, SET_USER} from '../actions/types';

export const logStatusReducer = (state=false, action) => {
    switch(action.type) {
        case SET_LOG_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export const checkAuthReducer = (state=true, action) => {
    switch(action.type) {
        case SET_CHECK_AUTH:
            return action.payload;
        default:
            return state
    }
}

export const userReducer = (state={userId: 0, email: "visitor@hoodchecker.com", username: "visitor"}, action) => {
    switch(action.type) {
        case SET_USER:
            return action.payload;
        default:
            return state
    }
}

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import qcApi from '../../apis';
import { InfoBanner } from '../elements/general';
import { BlueButton } from '../../subcomponents/elements/formElements';
import { setContentHeight }from '../../actions';
import history from '../../history';


const Privacy = props => {

    const {fingerprint, user, screenHeight, contentHeight} = props
    const [containerHeight, setContainerHeight] = useState({})

    useEffect(() => {

        const screenContent = document.getElementById("privacy_page").offsetHeight
        props.setContentHeight(screenContent)
        
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "privacy"
            })
        }
    
        if(contentHeight > screenHeight) {
            setContainerHeight({})
        } else {
            setContainerHeight({height: screenHeight})
        }
    }, [fingerprint, contentHeight])  

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="Privacy"
            subheading="Our policy on the collection and use of user data"/>
            <div className="ui container">
            <br/>
            <br/>
            <div className="ui basic segment" id="privacy_page" style={containerHeight}>
                <div className="ui small header item image_item">
                    Overview
                </div>
                <p>
                    Hoodchecker Limited operates the hoodchecker.com website, which provides the service. 
                </p>
                <p>
                    This page is used to inform you of our policy with the collection, use and disclosure of information in relation to your usage of our website. If you choose to use our website, then you agree to the collection and use of information in accordance with this policy. 
                </p>
                <p>
                    The information we collect is used for providing and improving our service to ensure you have the best experince possible. We will not pass or use your data to or with other third parties for commercial or marketing purposes. 
                </p>
                <div className="ui small header item image_item">
                    Information collection and use
                </div>
                <p>
                    For a better experience while using our website, we will require you to provide us with information, including but not limited to your email and country location. The provision of this information is entirely optional and only required if you choose to sign up as a registered user. 
                </p>
                <p>
                    You will be able to enjoy most of the products on our website as a non-registered user. However, we do restrict certain features for registered users to preserve the integrity of our application. 
                </p>
                <div className="ui small header item image_item">
                    Log data
                </div>
                <p>
                    We want to inform you that whenever your visit our service, we collect information that your browser sends to us as log data. This log data includes your computer's IP address, device identifier, pages of our service that you use and your engagement with our services. The purpose of logging this data is to maintain the security of our application, provide you with an optimised experience and enable the delivery of certain features on our website, which depends on user data. 
                </p>
                <div className="ui small header item image_item">
                    Cookies
                </div>
                <p>
                    Cookies are files containing data that is sent to your browser from the website that you visit. If you choose to refuse our cookies, you may not be able to experience some of the features for our registered users. 
                </p>
                <p>
                    Please note that our website uses these cookies only for authentication. We do not use these cookies to track your browser activites for our or other third party websites. 
                </p>
                <br/>
                <BlueButton
                    label="Back to home"
                    onClick={() => history.push("/")}
                    icon="home icon"
                    className="icon"/>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = ({fingerprint, user, screenHeight, contentHeight}) => ({fingerprint, user, screenHeight, contentHeight})

export default connect(mapStateToProps, {setContentHeight})(Privacy)
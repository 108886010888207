export const SET_VIEWPORT = "SET_VIEWPORT";
export const SET_SHOW_MOBILE_MENU = "SET_SHOW_MOBILE_MENU";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const RETRIEVE_SEARCH_RESULTS = "RETRIEVE_SEARCH_RESULTS";
export const SET_AREA_DATA = "SET_AREA_DATA";
export const SET_SHOW_DETAILS = "SET_SHOW_DETAILS";
export const SET_HOUSING_DATA = "SET_HOUSING_DATA";
export const SET_NURSERIES_DATA = "SET_NURSERIES_DATA";
export const SET_PARKS_DATA = "SET_PARKS_DATA";
export const SET_SHOW_MARKERS = "SET_SHOW_MARKERS";
export const SET_SCHOOLS_DATA = "SET_SCHOOLS_DATA";
export const SET_SCHOOL_GROUP = "SET_SCHOOL_GROUP";
export const SET_BUS_STOPS = "SET_BUS_STOPS";
export const SET_TRAIN_STATIONS = "SET_TRAIN_STATIONS";
export const SET_CHARGER_STATIONS = "SET_CHARGER_STATIONS";
export const SET_COMMUTING_DATA = "SET_COMMUTING_DATA";
export const SET_AFFLUENCE_DATA = "SET_AFFLUENCE_DATA";
export const SET_DEMOGRAPHICS_DATA = "SET_DEMOGRAPHICS_DATA";
export const SET_DIVERSITY_DATA = "SET_DIVERSITY_DATA";
export const SET_TENURE_AND_HEALTH_DATA = "SET_TENURE_AND_HEALTH_DATA";
export const SET_WORKER_DATA = "SET_WORKER_DATA";
export const SET_CRIME_DATA = "SET_CRIME_DATA";
export const SET_SHOW_MODAL = "SET_SHOW_MODAL"
export const SET_MODAL_TYPE = "SET_MODAL_TYPE"
export const SET_LOG_STATUS = "SET_LOG_STATUS"
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE";
export const SET_SIGNUP_MESSAGE = "SET_SIGNUP_MESSAGE";
export const SET_REQUEST_PASSWORD_MODAL = "SET_REQUEST_PASSWORD_MODAL";
export const SET_RESET_PASSWORD_MODAL = "SET_RESET_PASSWORD_MODAL";
export const SET_MODAL_HEADER = "SET_MODAL_HEADER";
export const SET_TEMPORARY_CREDENTIALS = "SET_TEMPORARY_CREDENTIALS";
export const SET_LOADING_TIMER = "SET_LOADING_TIMER";
export const SET_NO_RESULTS = "SET_NO_RESULTS";
export const SET_CONTENT_HEIGHT = "SET_CONTENT_HEIGHT";
export const SET_MESSAGE_MODAL = "SET_MESSAGE_MODAL" 
export const SET_MESSAGE_MODAL_CONTENT = "SET_MESSAGE_MODAL_CONTENT";
export const SET_CHECK_AUTH = "SET_CHECK_AUTH";
export const SET_AREA_VIEW_ID = "SET_AREA_VIEW_ID";
export const SET_FINGERPRINT = "SET_FINGERPRINT";
export const SET_CLIENT_COUNTRY = "SET_CLIENT_COUNTRY";
export const SET_USER = "SET_USER"
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
import { formatToThousandsOneDecimal, convertToPercentageDecimal } from './index';

const safetyDescription = value => {
    if(value < 2.5) {
        return "Area has amongst the highest levels of crime"
    } else if(value < 5) {
        return "Area has above average levels of crime"
    } else if(value < 7.5) {
        return "Area has below average levels of crime"
    } else if (value < 9) {
        return "Relatively safe with below average crime levels"
    } else {
        return "Considered very safe with low levels of crime"
    }
}

const affluenceDescription = (value, metric) => {

    const income = formatToThousandsOneDecimal(metric)

    if(value < 2.5) {
        return `Area is considered amongst the least affluent in its region with an average net income of £${income}k`
    } else if(value < 5) {
        return `Area has below average levels of affluence within its region with an average net income of £${income}k`
    } else if(value < 7.5) {
        return `Area has above average levels of affluence within its region with an average net income of £${income}k`
    } else if (value < 9) {
        return `Area is considered reasonably affluent relative to other areas in its region with an average net income of ${income}k`
    } else {
        return `Area is amongst the most affluent in its region with an average net income of £${income}k`
    }
}

const schoolDescription = value => {
    if(value < 2.5) {
        return "Area has the fewest schools in the country"
    } else if(value < 5) {
        return "Area has below average nursery and school places"
    } else if(value < 7.5) {
        return "Area has above average levels of nursery and school places"
    } else if (value < 9) {
        return "Area considered to have many nursery and school places"
    } else {
        return "Area has the highest levels of nursery and school places"
    }
}

const transitDescription = value => {
    if(value < 2.5) {
        return "Area has the lowest levels of access to public transport"
    } else if(value < 5) {
        return "Area has below average access to public transport"
    } else if(value < 7.5) {
        return "Area has above average levels of public transport"
    } else if (value < 9) {
        return "Area considered to have high levels of public transport access"
    } else {
        return "Area has the highest levels of public transport access"
    }
}

const ageDescription = (value, metric) => {
    
    const age = metric.toFixed(1)
    if(value < 2.5) {
        return `Area comprised of a young age demographic with an average age of ${age}`
    } else if(value < 5) {
        return `Area has below average age levels with an average age of ${age}`
    } else if(value < 7.5) {
        return `Area has above average age levels with an average age of ${age}`
    } else if (value < 9) {
        return `Area considered to have a relatively older population base with an average age of ${age}`
    } else {
        return `Area amonst the oldest population groups in the country with an average age of ${age}`
    }
}

const diversityDescription = (value, metric) => {

    const proportion = convertToPercentageDecimal(metric)

    if(value < 2.5) {
        return `Area has very low levels of diversity as ethnic minorities comprise ${proportion} of the total population`
    } else if(value < 5) {
        return `Area has below average levels of diversity as ethnic minorities comprise ${proportion} of the total population`
    } else if(value < 7.5) {
        return `Area has above average levels of diversity as ethnic minorities comprise ${proportion} of the total population`
    } else if (value < 9) {
        return `Area considered to have a relatively diverse population with ethnic minorities representing ${proportion} of the total population`
    } else {
        return `Area amongst the most diverse in the country with ethnic minorities representing ${proportion} of the total population`
    }
}

const housingDescription = (value, metric) => {

    const ratio = metric ? metric.toFixed(1) : 0 

    if(value < 2.5) {
        return `Area amongst the least affordable relative to income with a gross income to house ratio of ${ratio}`
    } else if(value < 5) {
        return `Area has below average levels of housing affodability relative to income with a gross income to house ratio of ${ratio}`
    } else if(value < 7.5) {
        return `Area has above average levels of housing affodability relative to income with a gross income to house ratio of ${ratio}`
    } else if (value < 9) {
        return `Area considered to have higher affordability levels relative to income with a gross income to house ratio of ${ratio}`
    } else {
        return `Area amonst the most affordable relative to income with a gross income to house ratio of ${ratio}`
    }
}
const professionalsDescription = value => {
    if(value < 2.5) {
        return "Area has the lowest levels of skills in the country"
    } else if(value < 5) {
        return "Area has below average levels of skills"
    } else if(value < 7.5) {
        return "Area has above average levels of skills"
    } else if (value < 9) {
        return "Area considered to have high levels of skills"
    } else {
        return "Area amongst the most skilled in the country"
    }
}

export const metricDescription = (metricType, score, metrics) => {

    const {
        income_net,
        average_age,
        minorities_proportion,
        house_prices_income_ratio
    } = metrics

    switch(metricType) {
        case "score_crime":
            return safetyDescription(score)
        case "score_affluence":
            return affluenceDescription(score, income_net)
        case "score_schools":
            return schoolDescription(score)
        case "score_transit":
            return transitDescription(score)
        case "score_age":
            return ageDescription(score, average_age)
        case "score_diversity":
            return diversityDescription(score, minorities_proportion)
        case "score_housing":
            return housingDescription(score, house_prices_income_ratio)
        case "score_professionals":
            return professionalsDescription(score)
        default:
            return ""
    }
}

export const generateLabel = value => {
    if(value < 2.5) {
        return "ui red horizontal label"
    } else if(value < 5) {
        return "ui orange horizontal label"
    } else if(value < 7.5) {
        return "ui grey horizontal label"
    } else if (value < 9) {
        return "ui green horizontal label"
    } else {
        return "ui green horizontal label"
    }
}

export const generateLabelInverse = value => {
    if(value < 2.5) {
        return "ui green horizontal label"
    } else if(value < 5) {
        return "ui green horizontal label"
    } else if(value < 7.5) {
        return "ui grey horizontal label"
    } else if (value < 9) {
        return "ui orange horizontal label"
    } else {
        return "ui red horizontal label"
    }
}


import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import { mobileScreenWidth } from '../constants';
import { 
    setShowDetails, 
    setNurseriesData, 
    setShowMarkers, 
    setParksData,
    setSchoolsData,
    setSchoolGroup,
    setBusStops, 
    setTrainStations,
    setChargerStations, 
    setNoData, 
    setLoadingTimer 
} from '../actions';
import { calcZoom, calcButtonColumnWidth, toTitleCase } from '../functions';
import { convertReferenceToField } from '../functions/labels';
import MarkersNurseries from './mapMarkers/MarkersNurseries';
import MarkersParks from './mapMarkers/MarkersParks';
import MarkersSchools from './mapMarkers/MarkersSchools';
import MarkersBusStops from './mapMarkers/MarkersBusStops';
import MarkersTrainStations from './mapMarkers/MarkersTrainStations';
import MarkersChargers from './mapMarkers/MarkersChargers';
import { MapLoader } from './elements/general';
import qcApi from '../apis';

const MapServices = props => {

    const {centre, area, dimensions} = props
    const perimeter = { color: '#305496' }

    const renderPolygons = dimensions.map((dimension, index) => (
        <Polygon pathOptions={perimeter} positions={dimension} key={index}/>
        ))
    
    return (
        <div className="chart_container" style={{height: 500}}>
            <MapContainer center={centre} zoom={calcZoom(area)} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>  contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                
                {renderPolygons}
                <MarkersNurseries name="nurseries"/>
                <MarkersParks name="parks"/>
                <MarkersSchools name="schools"/>
                <MarkersBusStops name="stops"/>
                <MarkersTrainStations name="stations"/>
                <MarkersChargers name="chargers"/>
            </MapContainer>  
        </div>
    )
}

const SectionMap = props => {

    const [schoolMenu, setSchoolMenu] = useState({active: "all", show: false})
    const {
        showMarkers, 
        screenWidth, 
        showSection, 
        areaMetaData: {centrepoint, ward, area, id, dimensions, district_code},
        dataStatusForMarkers,
        loadingTimer,
        pageViewId
    } = props
    const {
        nurseries, 
        parks, 
        schools, 
        busStops, 
        trainStations, 
        chargers,
    } = dataStatusForMarkers
    
    useEffect(() => {
        if(!centrepoint) {
            const timer = setTimeout(() => props.setNoData({mapServices: true}), 5000)
            props.setLoadingTimer(timer)
        }

        return (() => {
            props.setNoData({mapServices: false})
            clearTimeout(loadingTimer)
        })
    }, [centrepoint])

    if(!centrepoint) {
        return (
        <div className="ui segment">
            <MapLoader/>
        </div>
        )
    } 
    
    const locationId = ward ? id : district_code
    const focused = showSection.amentiesAndServices
    const iconSize = screenWidth > mobileScreenWidth ? "big" : null
    const buttonColumns = calcButtonColumnWidth(screenWidth)
    const icon = focused ? "minus" : "plus"
    const centre = [centrepoint[1], centrepoint[0]]

    const unhideContent = () => {
        if(focused) {
            props.setShowDetails({amentiesAndServices: false})
        } else {
            props.setShowDetails({amentiesAndServices: true})
        }
    }



    const fetchItemLocations = (location, reference) => {
        const field = convertReferenceToField(reference)
        qcApi.patch("/area-view", {field, page_view_id: pageViewId})
        switch(reference) {
            case "nurseries":
                if(!nurseries) {props.setNurseriesData(location)}
                break
            case "parks":
                if(!parks) {props.setParksData(location)}
                break
            case "schools":
                if(!schools) {props.setSchoolsData(location)}
                break
            case "stops":
                if(!busStops) {props.setBusStops(location)}
                break
            case "stations":
                if(!trainStations) {props.setTrainStations(location)}
                break
            case "chargers":
                if(!chargers) {props.setChargerStations(location)}
                break
            default:
                return
        }
    }

    const buttons = [
        {label: "Nurseries", color: "yellow", ref: "nurseries"}, 
        {label: "Parks", color: "olive", ref: "parks"}, 
        {label: "Schools", color: "green", ref: "schools"}, 
        {label: "Buses", color: "teal", ref: "stops"}, 
        {label: "Trains", color: "blue", ref: "stations"}, 
        {label: "Charging", color: "brown", ref: "chargers"}
    ]
    
    const renderButtons = buttons.map((item, index) => {

        const outline = showMarkers[item.ref] ? null : "basic"
        
        const onButtonClick = () => {
            fetchItemLocations(locationId, item.ref)
            const currentMarkers = showMarkers
            currentMarkers[item.ref] = !showMarkers[item.ref]
            props.setShowMarkers({...currentMarkers})
        }

        if (item.ref === "schools") {

            const dropdownList = () => {
                const options = ["all", "primary", "secondary"]
                const onSelect = option => {
                    props.setSchoolGroup(option)
                    setSchoolMenu({active: option, show: false})
                    const currentMarkers = showMarkers
                    currentMarkers[item.ref] = true
                    props.setShowMarkers({...currentMarkers})
                }
                return options.map((value, index) => {
                    const active = schoolMenu.active === value ? "active" : null
                    return (
                        <div 
                            className={`ui button item ${active} school_option_type`} 
                            key={index} onClick={() => onSelect(value)}
                            href="#"
                        >
                            {toTitleCase(value)}
                        </div>
                        )})
                }
            const dropdown = (
                <div 
                    className="ui vertical menu dropdown_menu" 
                    id="school_dropdown"
                    onMouseLeave={() => setSchoolMenu({...schoolMenu, show: false})}
                    >
                    {dropdownList()}
                </div>
            )
            
            return ( // School button with additional dropdown filter
                <div 
                    className={`${buttonColumns} wide column`} key={index}
                >
                    <div className="ui fluid buttons grouped_buttons" >
                        <div 
                            className={`ui green ${outline} fluid button`} 
                            onClick={onButtonClick} 
                            style={{borderRadius: 4, textAlign: "center"}}
                        >
                            Schools
                        </div>
                        <div 
                            className="ui dropdown icon green button right_aligned_dropdown"
                            onClick={() => setSchoolMenu({...schoolMenu, show: !schoolMenu.show})}
                            style={{position: "absolute"}}
                            >
                            <i className="dropdown icon"/>
                        </div>
                    </div>
                    {schoolMenu.show ? dropdown : null}
                </div>
            )
        } else {
            return ( // Other buttons
            <div className={`${buttonColumns} wide column map_buttons`} key={index}>
                <button className={`ui ${item.color} ${outline} fluid button`} onClick={onButtonClick}>
                    {item.label}
                </button>
            </div>
            )
        }
    })
    
    return(
        <div className="ui yellow segment">
            <div className="ui relaxed grid">
                <div className="fourteen wide column middle aligned">
                    <div className="ui medium header">Amenities and Services</div> 
                </div>
                <div className="two wide column right aligned">
                    <i className={`${icon} circle ${iconSize} icon`} onClick={unhideContent}/>
                </div>
                {focused 
                ? <div className="six column row">
                    {renderButtons}
                  </div>
                : null}
                {focused 
                ? <MapServices 
                    centre={centre} 
                    area={area} 
                    dimensions={dimensions}
                    /> 
                : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const {
        screenWidth, 
        showSection, 
        areaMetaData, 
        showMarkers, 
        schoolGroup,
        nurseries,
        parks,
        schools,
        busStops,
        trainStations,
        chargers,
        noData,
        loadingTimer,
        pageViewId
    } = state

    const dataStatusForMarkers = {
        nurseries: nurseries.data_status,
        parks: parks.data_status,
        schools: schools.data_status,
        busStops: busStops.data_status,
        trainStations: trainStations.data_status,
        chargers: chargers.data_status
    }

    return {
        screenWidth, 
        showSection, 
        areaMetaData, 
        showMarkers, 
        schoolGroup,
        dataStatusForMarkers,
        noData,
        loadingTimer,
        pageViewId
    }
}

export default connect(mapStateToProps, {
    setShowDetails, 
    setNurseriesData, 
    setShowMarkers,
    setParksData,
    setSchoolsData,
    setSchoolGroup,
    setBusStops,
    setTrainStations,
    setChargerStations,
    setNoData, 
    setLoadingTimer
})(SectionMap)

import React from 'react';
import { 
    ComposedChart,
    Bar, 
    XAxis,
    YAxis,
    LabelList,
    ResponsiveContainer,
} from 'recharts';
import { roundTwoDecimals } from '../../functions';
import { chartColours } from '../../constants';

export const PercentileChart = props => {
  const {data, locations: {ward}} = props
  const colours = [chartColours[1][3], chartColours[1][0], chartColours[1][2]]
  
  const grossIncomeLabels = props => {
      const { x, y, width } = props;
      const percentileValue = data[0].more_deprived + 0.5
      const percentileLabel = roundTwoDecimals(percentileValue) + "%"
      
      const textAnchor = value => {  
        if(value < 5) {return "left"} 
        else if (value > 90) {return "end"} 
        else {return "middle"}
      }
      
      return (
        <>
          <text 
            x={x + width / 2} y={y - 40} 
            fill={colours[1]} 
            textAnchor={textAnchor(percentileValue)}
            dominantBaseline="middle"
          >
            {ward}
          </text>
          <text 
            x={x + width/2} 
            y={y - 10} fill={colours[1]} 
            textAnchor={textAnchor(percentileValue)}
            dominantBaseline="middle"
          >
              {percentileLabel}
          </text>
        </>
      )
  }

  const mostDeprivedLabel = props => {
    const { x, y } = props;
    
    return (
        <text x={x} y={y + 140} textAnchor="left" dominantBaseline="middle">
          Most deprived
        </text>
    )
  }
  const leastDeprivedLabel = props => {
    const { x, y, width } = props;
    
    return (
        <text x={x + width} y={y + 140} textAnchor="end" dominantBaseline="middle">
          Least deprived
        </text>
    )
  }

  return (
      <ResponsiveContainer width="100%" height="70%" id="percentile_chart">
          <ComposedChart 
              data={data} 
              layout="vertical"
              margin={{
                  top: 50,
                  right: 20,
                  bottom: 30,
                  left: 20,
                }}
          >
              <XAxis type="number"/>
              <YAxis type="category" hide/>
              <Bar dataKey="more_deprived" stackId="a" fill={colours[0]} name="More deprived Deprived">
                <LabelList content={mostDeprivedLabel}/>
              </Bar>
              <Bar dataKey="ward" stackId="a" fill={colours[1]} name={ward}>
                <LabelList content={grossIncomeLabels}/>
              </Bar>    
              <Bar dataKey="less_deprived" stackId="a" fill={colours[2]} name="Less Deprived">
                <LabelList content={leastDeprivedLabel}/>
              </Bar>     
          </ComposedChart>
      </ResponsiveContainer>
  )
}
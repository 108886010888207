import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import qcApi from '../../apis';
import { InfoBanner } from '../elements/general';
import { BlueButton } from '../../subcomponents/elements/formElements';
import { setContentHeight } from '../../actions';
import history from '../../history';


const Terms = props => {

    const {fingerprint, user, contentHeight, screenHeight} = props
    const [containerHeight, setContainerHeight] = useState({})
    useEffect(() => {

        const screenContent = document.getElementById("terms_page").offsetHeight
        props.setContentHeight(screenContent)
        
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "terms"
            })
        }

        if(contentHeight > screenHeight) {
            setContainerHeight({})
        } else {
            setContainerHeight({height: screenHeight})
        }

    }, [fingerprint, contentHeight])  

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="Terms and conditions of use"
            subheading="Our policy on using our website"/>
            <div className="ui container">
            <br/>
            <br/>
            <div className="ui basic segment" id="terms_page" style={containerHeight}>
                <div className="ui small header item image_item">
                    Overview
                </div>
                <p>
                    Welcome to 'hoodchecker.com'. If you continue to use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern hoodchecker's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
                </p>
                <p>
                    The term 'hoodchecker', 'Hoodchecker Limited', 'us' or 'we' refers to the owner of the website whose registered office is 111 Seven Sisters Road, London, N7 7FN. The term 'you' refers to the user or viewer of our website.
                </p>
                <div className="ui small header item image_item">
                    Terms of use
                </div>
                <p>
                    The use of this website is subject to the following terms of use:
                </p>
                <ul className="ui bulleted list">
                    <li className="item">
                        The content of the pages of this website is subject to change without notice.
                    </li>
                    <br/>
                    <li className="item">
                        This website uses cookies to verify and authenticate users only. We do not store any personal information for use by third parties.
                    </li>
                    <br/>
                    <li className="item">
                        Neither we nor any sources referenced on this website provide any warranty or guarantee as to the accuracy, correctness, or completeness of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                    </li>
                    <br/>
                    <li className="item">
                        Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable.
                    </li>
                    <br/>
                    <li className="item">
                        This website contains material, which may be sourced from other websites. To the fullest extent possible, we will reference the source of this information where the source is identifiable. You are responsible for usage of any information on this website subject to the copyright notices of the intellectual property holder. 
                    </li>
                    <br/>
                    <li className="item">
                        Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence. We reserve the right to restrict and exclude the user when such unauthorized activites are identified.  Activites that consistute unauthorised use include but are not limited to:
                        <br/>
                        <ol className="ui list">
                            <li value="-">Creating unauthentic registered accounts for any purpose</li>
                            <li value="-">Submitting forms from this website for unsolicited marketing and spamming</li>
                            <li value="-">The use of bots and other programmtic tools to interact with our website</li>
                        </ol>
                    </li>
                    <br/>
                    <li className="item">
                        From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                    </li>
                    <br/>
                    <li className="item">
                        Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.
                    </li>
                </ul>
                <br/>
                <BlueButton
                    label="Back to home"
                    onClick={() => history.push("/")}
                    icon="home icon"
                    className="icon"/>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = ({
    fingerprint, 
    user,
    contentHeight,
    screenHeight
}) => ({
    fingerprint, 
    user,
    contentHeight,
    screenHeight
})

export default connect(mapStateToProps, {setContentHeight})(Terms)
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { tabletScreenWidth } from '../../constants';
import { InfoBanner } from '../../subcomponents/elements/general';
import { BlueButton } from '../../subcomponents/elements/formElements';
import qcApi from '../../apis';
import history from '../../history';

let About = ({screenWidth, fingerprint, user}) => {

    
    const [showFull, setShowFull] = useState(null)

    useEffect(() => {
        setShowFull(screenWidth > tabletScreenWidth)
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "about"
            })
        }
    }, [screenWidth, fingerprint])    
    
    const columnWidths = showFull ? "eight wide column" : null
    
    const aboutImage = showFull 
    ? <div className={`${columnWidths} custom_about_image_background`}/>
    : null
    
    const creatorImage = showFull
    ? <div className={`${columnWidths} custom_about_image_creator_background`}/>
    : null

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="About"
            subheading="Guiding you to every area in England and Wales"/>
            <div className="custom_blue_background">
                <div className="ui container">
                    <div className="ui grid">
                        {aboutImage}
                        <div className={`${columnWidths} custom_about_body`}>
                            <div className="custom_about_body_text">
                                <div className="custom_about_description_heading">
                                    How it all started
                                </div>
                                    <br/>
                                    <p>
                                        Hoodchecker started as a personal research project that was intended to provide me with detailed insights on where to settle and live. After collating the data, I thought that this effort and the process used to interpret this data would be useful to others and decided to create a free and publicably available tool for others to use.   
                                    </p>
                                    <p>
                                        While there many useful forums that provided anecdotes on which places were ideal for me and my circumstances, I didn't easily find a comprensive source of information across different areas such as the economic socio-demographic or diversity of a particiular area. 
                                    </p>
                                    <p>
                                        My mission is to provide you with an objective data-driven overview of every area in England and Wales to enable you to understand an area of interest. I have sourced this comprehensive dataset from the Office of National Statistics as well as other official government sources at national and local authoritiy level. This data has been consistently processed using detailed methods to provide you with detailed points of comparisons. 
                                    </p>
                                    <p>
                                        I hope this information provide you with valuable insights.  
                                    </p>
                                </div>
                                <br/>
                        </div>
                    </div>
                </div>
                </div>
            <div className="custom_yellow_background">
                <div className="ui container">
                    <div className="ui grid">
                        <div className={`${columnWidths} custom_about_body`}>
                            <div className="custom_about_creator_text custom_about_body_text">
                                <div className="custom_about_creator_heading">
                                    About the creator
                                </div>
                                    <br/>
                                    <p>
                                        Welcome! I'm the hoodchecker.com and hope this website provides some use on understanding an area of interest. 
                                    </p>
                                    <p>
                                        Originally from Australia, I moved to the UK a few years ago and currently work as an IT consultant specialising in Finance systems. I have a finance background, starting my career as an auditor with the Big 4 firm. However, my real passion lies with technology and programming, particularly in Python and Javascript. 
                                    </p>
                                    <p>
                                        I currently live in North London with my wife and two girls. 
                                    </p>
                                    <p>
                                        I'm always up for meeting up new folks with common interests in startups and technology so if you live in London or here for a visit, send me a message through <Link to="/contact" className="custom_about_creator_text custom_bold_text">clicking here to contact</Link>
                                    </p>
                                    <br/>
                                    <BlueButton
                                        label="Back to home"
                                        onClick={() => history.push("/")}
                                        icon="home icon"
                                        className="icon"/>
                                </div>
                                <br/>
                        </div>
                        {creatorImage}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({screenWidth, fingerprint, user}) => ({screenWidth, fingerprint, user})

export default connect(mapStateToProps)(About)
import L from 'leaflet';

export const ukMap = {
    name: "united_kingdom",
    canvas:
      "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/united-kingdom/uk-counties.json",
    dimensions: [4, -54.4, -10],
    width: 600,
    height: 600,
    scale: 4000
  };


export const dummyPin = new L.Icon({
  iconUrl: require('./dummy.png'),
  iconRetinaUrl: require('./dummy.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const parksIcon = new L.Icon({
  iconUrl: require('./parks_icon.png'),
  iconRetinaUrl: require('./parks_icon.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const primaryIcon = new L.Icon({
  iconUrl: require('./prim-school-icon.png'),
  iconRetinaUrl: require('./prim-school-icon.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const secondaryIcon = new L.Icon({
  iconUrl: require('./high-school-icon.png'),
  iconRetinaUrl: require('./high-school-icon.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const busStopIcon = new L.Icon({
  iconUrl: require('./bus-stop.png'),
  iconRetinaUrl: require('./bus-stop.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const stationIcon = new L.Icon({
  iconUrl: require('./national_rail.png'),
  iconRetinaUrl: require('./national_rail.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const tubeIcon = new L.Icon({
  iconUrl: require('./underground.png'),
  iconRetinaUrl: require('./underground.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })

export const chargerIcon = new L.Icon({
  iconUrl: require('./charger.png'),
  iconRetinaUrl: require('./charger.png'),
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: null
  })
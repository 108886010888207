export const ethnicLabels = text => {
    switch(text) {
        case "eth_white":
        return "White"
        case "mixed_other": 
        return "Mixed/Other"
        case "eth_asian":
        return "Asian"
        case "eth_black":
        return "Black"
        default:
        return "White"
    }
}

export const transportMethodLabels = text => {
    switch(text) {
        case "method_car":
        return "Car"
        case "method_public": 
        return "Public Transport"
        case "method_motorcycle":
        return "Motorcycle"
        case "method_other":
        return "Bike or Walk"
        default:
        return "White"
    }
}

export const englishLanguageLabels = text => {
    switch(text) {
        case "eng_full":
        return "English fully spoken"
        case "non_or_partial": 
        return "English partially / not spoken"
        default:
        return "English is fully spoken"
    }
    }

export const ageBracketLabels = text => {
    switch(text) {
        case "age_4":
            return "0-4"
        case "age_9":
            return "5-9"
        case "age_15":
            return "10-15"
        case "age_19":
            return "16-19"
        case "age_24":
            return "20-24"
        case "age_34":
            return "25-34"
        case "age_49":
            return "35-49"
        case "age_64":
            return "50-64"
        case "age_74":
            return "65-74"
        case "age_85":
            return "75-84"
        default:
            return "85+"
            }
    }

export const maritalStatusLabels = text => {
    switch(text) {
        case "status_never":
            return "Never married"
        case "married_civil_pship":
            return "Married or in civil partnership"
        default:
            return "Separated, divorced or widowed"
            }
}

export const languageLabels = text => {
    switch(text) {
        case "lang_english":
            return "English"
        case "lang_french":
            return "French"
        case "lang_portuguese":
            return "Portuguese"
        case "lang_spanish":
            return "Spanish"
        case "lang_italian":
            return "Italian"
        case "lang_german":
            return "German"
        case "lang_polish":
            return "Polish"
        case "lang_slovak":
            return "Slovak"
        case "lang_czech":
            return "Czech"
        case "lang_romanian":
            return "Romanian"
        case "lang_lithuanian":
            return "Lithuanian"
        case "lang_latvian":
            return "Latvian"
        case "lang_hungarian":
            return "Hungarian"
        case "lang_bulgarian":
            return "Bulgarian"
        case "lang_greek":
            return "Greek"
        case "lang_dutch":
            return "Dutch"
        case "lang_swedish":
            return "Swedish"
        case "lang_danish":
            return "Danish"
        case "lang_finnish":
            return "Finnish"
        case "lang_estonian":
            return "Estonian"
        case "lang_slovenian":
            return "Slovenian"
        case "lang_maltese":
            return "Maltese"
        case "lang_albanian":
            return "Albanian"
        case "lang_ukrainian":
            return "Ukrainian"
        case "lang_bos_cro_serb":
            return "Bosnian, Croatian, Serbian"
        case "lang_russian":
            return "Russian"
        case "lang_turkish":
            return "Turkish"
        case "lang_arabic":
            return "Arabic"
        case "lang_hebrew":
            return "Hebrew"
        case "lang_kurdish":
            return "Kurdish"
        case "lang_persion":
            return "Persion"
        case "lang_pashto":
            return "Pashto"
        case "lang_urdu":
            return "Urdu"
        case "lang_hindi":
            return "Hindi"
        case "lang_panjabi":
            return "Panjabi"
        case "lang_pakistani":
            return "Pakistani"
        case "lang_bengali":
            return "Bengali"
        case "lang_gujarati":
            return "Gujarati"
        case "lang_marathi":
            return "Marathi"
        case "lang_telugu":
            return "Telugu"
        case "lang_tamil":
            return "Tamil"
        case "lang_malayalam":
            return "Malayalam"
        case "lang_sinhala":
            return "Sinhala"
        case "lang_nepalese":
            return "Nepalese"
        case "lang_other_south_asian":
            return "Other South Asian"
        case "lang_mandarin":
            return "Mandarin"
        case "lang_cantonese":
            return "Cantonese"
        case "lang_other_chinese":
            return "Other Chinese"
        case "lang_japanese":
            return "Japanese"
        case "lang_korean":
            return "Korean"
        case "lang_vietnamese":
            return "Vietnamese"
        case "lang_thai":
            return "Thai"
        case "lang_malay":
            return "Malay"
        case "lang_filipino":
            return "Filipino"
        case "lang_other_east_asian":
            return "Other East Asian"
        case "lang_creole":
            return "Creole"
        case "lang_amharic":
            return "Amharic"
        case "lang_tigrinya":
            return "Tigrinya"
        case "lang_somali":
            return "Somali"
        case "lang_krio":
            return "Krio"
        case "lang_akan":
            return "Akan"
        case "lang_yoruba":
            return "Yoruba"
        case "lang_igbo":
            return "Igbo"
        case "lang_swahili_kiswahili":
            return "Swahili or Kiswahili"
        case "lang_luganda":
            return "Luganda"
        case "lang_lingala":
            return "Lingala"
        case "lang_shona":
            return "Shona"
        case "lang_afrikaans":
            return "Afrikaans"
        case "lang_nigerian":
            return "Nigerian"
        case "lang_other_west_african":
            return "Other West African"
        case "lang_other_african":
            return "Other African"
        case "lang_sign":
            return "Sign"
        case "total":
            return "Total"
        default:
            return "Other"
            }
}

export const majorCrimeLabels = text => {
    switch(text) {
        case "crime_major_violence_sexual":
            return "Violent or Sexual"
        case "crime_major_asb":
            return "Anti-social behavior"
        case "crime_major_damage_arson":
            return "Damage or arson"
        case "crime_major_weapons":
            return "Weapons"
        case "crime_other_public_order":
            return "Public order"
        case "crime_other_drugs":
            return "Drugs"
        default:
            return "Other"
            }
}
export const theftCrimeLabels = text => {
    switch(text) {
        case "crime_theft_burglary":
            return "Burglary"
        case "crime_theft_vehicle":
            return "Vehicle"
        case "crime_theft_robbery":
            return "Robbery"
        case "crime_theft_shoplifting":
            return "Shoplifting"
        case "crime_theft_bicycle":
            return "Bicycle"
        case "crime_theft_person_theft":
            return "Personal"
        default:
            return "Other theft"
            }
}
export const employmentLabels = text => {
    switch(text) {
        case "emp_employed":
            return "Employed"
        case "emp_self_employed":
            return "Self-employed"
        case "emp_retired":
            return "Retired"
        case "emp_unemployed_studying":
            return "Studying"
        default:
            return "Unable to work"
            }
}

export const industryLabels = text => {
    switch(text) {
        case "ind_agri_forestry_fishing":
            return "Agriculture, forestry, and fishing"
        case "ind_mining":
            return "Mining"
        case "ind_manufacturing":
            return "Manufacturing"
        case "ind_utilities":
            return "Utilities"
        case "ind_waste_mgt":
            return "Waste Mgt"
        case "ind_construction":
            return "Construction"
        case "ind_vehicle_repairs":
            return "Vehicle Repairs"
        case "ind_transport_storage":
            return "Transport and storage"
        case "ind_hospitality":
            return "Hospitality"
        case "ind_info_comm":
            return "Information and communications"
        case "ind_fin_services":
            return "Financial services"
        case "ind_real_estate":
            return "Real estate"
        case "ind_professional_serv":
            return "Professional services"
        case "ind_admin":
            return "Administration"
        case "ind_public_services_defence":
            return "Public services and defence"
        case "ind_education":
            return "Education"
        case "ind_health_social":
            return "Health and social services"
        default:
            return "Other"
            }
}

export const occupationLabels = text => {
    switch(text) {
        case "occ_managers_directors":
            return "Managers or directors"
        case "occ_professionals":
            return "Professionals"
        case "occ_technical":
            return "Technical"
        case "occ_administrative":
            return "Administrative"
        case "occ_skilled_trades":
            return "Skilled trades"
        case "occ_hospitality_care":
            return "Hospitality or care"
        case "occ_sales_services":
            return "Sales and services"
        case "occ_machine_ops":
            return "Machine operators"
        default:
            return "Basic"
            }
}

export const tenureLabels = text => {
    switch(text) {
        case "tenure_outright":
            return "Outright ownership"
        case "tenure_social":
            return "Social housing"
        case "tenure_mortgaged_or_shared_ownership":
            return "Mortgage or shared ownership"
        default:
            return "Private rental"
            }
}

export const healthLabels = text => {
    switch(text) {
        case "health_great":
            return "Great"
        case "health_good":
            return "Good"
        case "health_fair":
            return "Fair"
        case "health_bad":
            return "Bad"
        default:
            return "Terrible"
            }
}

export const needleColor = score => {
    if(score < 4) {
        return "red"
    } else if(score < 7.5) {
        return "orange"
    } else {
        return "green"
    }
}

export const metricLabels = text => {
    switch(text) {
        case "score_affluence":
            return "Affluence"
        case "score_crime":
            return "Area safety"
        case "score_housing":
            return "House affordability"
        case "score_schools":
            return "Schools"
        case "score_age":
            return "Age index"
        case "score_diversity":
            return "Diversity"
        case "score_professionals":
            return "Worker profile"
        case "score_transit":
            return "Public transportation"
        default:
            return "Terrible"
            }
}


export const convertReferenceToField = ref => {
    switch(ref) {
        case "nurseries":
            return "view_amenities_nurseries"
        case "parks":
            return "view_amenities_parks"
        case "schools":
            return "view_amenities_schools"
        case "stops":
            return "view_amenities_buses"
        case "stations":
            return "view_amenities_trains"
        case "chargers":
            return "view_amenities_charging"
        default:
            return
    }
}

export const convertSectionNameToField = ref => {
    switch(ref) {
        case "housing":
            return "view_housing"
        case "gettingAround":
            return "view_commuting"
        case "affluence":
            return "view_affluence"
        case "demographics":
            return "view_demographics"
        case "workerProfiles":
            return "view_workers"
        case "tenureAndHealth":
            return "view_ownership_health"
        case "diversity":
            return "view_diversity"
        case "crime":
            return "view_crime"
        default:
            return
    }
}


import { 
    chartColours, 
    mediumScreenWidth,
    tabletScreenWidth, 
    largeScreenWidth,
    hugeScreenWidth
} from '../constants';

export const calcZoom = area => {
    if(area < 2000) {
        return 20
    } else if(area < 10000) {
        return 18
    } else if(area < 20000) {
        return 17
    } else if(area < 100000) {
        return 16
    } else if(area < 1000000) {
        return 15
    } else if(area < 2000000) {
        return 14
    } else if(area < 20000000) {
        return 13
    } else if(area < 100000000) {
        return 12
    } else if(area < 600000000) {
        return 11
    } else {
        return 10
    }
}

export const transformDatasetToThousands = data => {
    data.map(dataset => {
        dataset["ward"] = Math.round(dataset["ward"] / 1000)
        dataset["local_authority"] = Math.round(dataset["local_authority"] / 1000)
        dataset["region"] = Math.round(dataset["region"] / 1000)
        dataset["country"] = Math.round(dataset["country"] / 1000)
        dataset["district"] = Math.round(dataset["district"] / 1000)
        return dataset
    })
    
    return data
}
export const roundDatasetToInteger = data => {
    data.map(dataset => {
        dataset["ward"] = Math.round(dataset["ward"])
        dataset["local_authority"] = Math.round(dataset["local_authority"])
        dataset["region"] = Math.round(dataset["region"])
        dataset["country"] = Math.round(dataset["country"])
        return data
    })
    return data
}



export const formatToThousands = value => Math.round(value / 1000)
export const formatToThousandsOneDecimal = value => (value / 1000).toFixed(1)

export const formatNumToLocalString = value => value.toLocaleString()

export const roundTwoDecimals = value => Math.round( value * 100 + Number.EPSILON ) / 100

export const convertToPercentageDecimal = value => (value * 100).toFixed(1)+"%"

export const convertToPercentage = value => (value * 100)+"%"

export const calcButtonColumnWidth = screensize => {

    if(screensize > 1000) {
        return null
    } else if (screensize > 500) {
        return "five"
    } else {
        return "eight"
    }
}

export const toTitleCase = word => {
    const wordList = word.split("")
    wordList.map((letter, index) => {
        if(index === 0) {
            wordList[index] = letter.toUpperCase()
        } else if (letter === " ") {
            wordList[index + 1] = wordList[index + 1].toUpperCase()
        }
        return wordList.join("")
    })
    return wordList.join("")
} 

export const parseDomain = dataset => [
    0,
    Math.max(
      Math.max.apply(
        null,
        dataset.map((entry) => entry.value),
      ),
      Math.max.apply(
        null,
        dataset.map((entry) => entry.value),
      ),
    ),
  ];

export const invertChartColours = hex => {

    let invertedColour

    const colourIndex = chartColours[0].findIndex(item => item === hex)
    invertedColour = chartColours[1][colourIndex]

    if(!invertedColour) {
        const colourIndex = chartColours[1].findIndex(item => item === hex)
        invertedColour = chartColours[0][colourIndex]
    }
    return invertedColour
}

export const responsiveBarChart = (screensize, numberFormat) => {
    
    let xFontSize = 14
    let layout = "horizontal"
    let xType = "category"
    let yType = "number"
    let xKey = "name"
    let yKey
    let yTickFormatter = numberFormat
    let xTickFormatter
    let yCustomLabelMargin = -10

    if(screensize > hugeScreenWidth) {
    } else if(screensize > largeScreenWidth) {
        xFontSize = 13
    } else if(screensize > mediumScreenWidth) {
        xFontSize = 12
    } else if (screensize > tabletScreenWidth) {
        xFontSize = 10
    } else {
        xFontSize = 12
        layout="vertical"
        xType = "number"
        yType = "category"
        yKey = "name"
        xKey = null
        yTickFormatter = null
        xTickFormatter = formatToThousands
        yCustomLabelMargin = 12
    }
    return {
        xFontSize, 
        layout, 
        xType, 
        yType, 
        xKey, 
        yKey, 
        xTickFormatter, 
        yTickFormatter, 
        yCustomLabelMargin
    }

}

export const responsiveWaterfallChart = screensize => {
    
    let xFontSize = 14
    let layout = "horizontal"
    let xType = "category"
    let yType = "number"
    let xKey = "name"
    let yKey 
    let yTickFormatter = formatNumToLocalString
    let xTickFormatter
    let yCustomLabelMargin = false
    let xCustomLabelMargin = false
    let textAnchor = "middle"

    if(screensize > hugeScreenWidth) {
    } else if(screensize > largeScreenWidth) {
        xFontSize = 13
    } else if(screensize > mediumScreenWidth) {
        xFontSize = 12
    } else if (screensize > tabletScreenWidth) {
        xFontSize = 10
    } else {
        xFontSize = 12
        layout="vertical"
        xType = "number"
        yType = "category"
        yKey = "name"
        xKey = null
        yTickFormatter = null
        xTickFormatter = formatToThousands
        yCustomLabelMargin = true
        xCustomLabelMargin = true
        textAnchor = "left"
    }
    return {
        xFontSize, 
        layout, 
        xType, 
        yType, 
        xKey, 
        yKey, 
        xTickFormatter, 
        yTickFormatter, 
        yCustomLabelMargin,
        xCustomLabelMargin,
        textAnchor
    }

}

export const xAxisLabels = (screensize, label) => {
    
    let xLabel

    const renderAxisLabel = limit => label.length > limit 
    ? label.substring(0, limit - 2) + ".." 
    : label

    if(screensize > hugeScreenWidth) {
        xLabel = renderAxisLabel(14)
    } else if(screensize > largeScreenWidth) {
        xLabel = renderAxisLabel(12)
    } else if(screensize > mediumScreenWidth) {
        xLabel = renderAxisLabel(8)
    } else if (screensize > tabletScreenWidth) {
        xLabel = renderAxisLabel(8)
    } else {
        xLabel = renderAxisLabel(8)
    }
    return xLabel

}

export const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
export const validUsername = /^[A-Za-z0-9]*$/
export const validPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
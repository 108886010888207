import React from 'react';
import { connect } from 'react-redux';
import MultiMarkers from '../MultiMarkers';
import { stationIcon, tubeIcon  } from '../../resources';

const MarkersTrainStations = props => {
    const {trainStations: {national_rail, tube}, showMarkers, name} = props
    if(!showMarkers[name] || !national_rail || !tube) {return null}
    
    const nationalLocations = national_rail.map(item => {
        const network = item.network ? <p>Network: {item.network}</p> : null
        const stationId = item.station_id ? <p>ID: {item.station_id}</p> : null
        const region = item.region ? <p>Region: {item.region}</p> : null
        const passengers = item.passengers ? <p>Passengers: {item.passengers}</p> : null

        const tooltip = (
            <>
                {network}
                {stationId}
                {region}
                {passengers}
            </>
        )
        return ({
            coords: [item.lat, item.lon],
            popup: item.station,   
            tooltip
        })
    })

    const tubeLocations = tube.map(item => {

        const passengers = item.passengers ? <p>Passengers: {item.passengers}</p> : null
        const operator = <p>Operator: TFL</p>
        
        const tooltip = (
            <>
            {passengers}
            {operator}
            </>
        )

        return({
            coords: [item.lat, item.lon],
            popup: item.station,
            tooltip
        })
    })
    
    return(
        <>
        <MultiMarkers
            data={nationalLocations}
            icon={stationIcon}
        />
        <MultiMarkers
            data={tubeLocations}
            icon={tubeIcon}
        />
        </>
    )
}

const mapStateToProps = ({showMarkers, trainStations}) => {
    return ({showMarkers, trainStations})
}

export default connect(mapStateToProps, {})(MarkersTrainStations)
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  mobileScreenWidth,
  smallScreenWidth,
  mediumScreenWidth,
} from "../constants";
import { setShowDetails, setCommutingData, setNoData, setLoadingTimer } from "../actions";
import { DoublePieChart } from "./charts/DoublePieChart";
import { StackedChart } from "./charts/StackedChart";
import { transportMethodLabels } from "../functions/labels";
import { ChartLoader } from './elements/general'

const GettingAroundCharts = (props) => {
  const {
    screenWidth,
    locations,
    data: { travel_method, numcar_data, travel_distance },
  } = props;
  const { ward, region, district } = locations;

  if (!travel_distance) {
    return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
      );
  }

  const locationName = ward ? ward : district
  const colWidth = screenWidth < smallScreenWidth ? "sixteen" : "eight";
  const showFull = screenWidth > mediumScreenWidth;

  return (
    <>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">
          {`Residents mode of transport relative to ${region}`}{" "}
        </div>
        <DoublePieChart
          data={travel_method}
          labelCallback={transportMethodLabels}
          showFull={showFull}
        />
        <div className="ui tiny blue header inner_pie_label">{locationName}</div>
        <div className="ui tiny blue header outer_pie_label">{region}</div>
      </div>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">Car ownership by household</div>
        <StackedChart
          data={numcar_data}
          locations={locations}
          showFull={showFull}
        />
      </div>
    </>
  );
};

const SectionGettingAround = props => {
  const {
    loadingTimer,
    screenWidth,
    showSection,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country,
      district_code,
      local_authorities, 
    },
    commutingData,
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({commute: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({commute: false})
        clearTimeout(loadingTimer)
    })
}, [ward, district_code])

  if(!ward && !district_code) {
    return (
      <div className="ui basic segment">
        <ChartLoader/>
    </div>
    )
  }
  
  const focused = showSection.gettingAround;
  const {data_status} = commutingData
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };

  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ gettingAround: false });
    } else {
      props.setShowDetails({ gettingAround: true }, pageViewId);
      if(!data_status) {props.setCommutingData(locationId, locationAuthName)}
    }
  };

  return (
    <div className="ui teal segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">Getting Around</div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <GettingAroundCharts
            data={commutingData}
            locations={locations}
            screenWidth={screenWidth}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  areaMetaData,
  commutingData,
  pageViewId,
  noData,
  loadingTimer
}) => ({ 
  screenWidth, 
  showSection, 
  areaMetaData, 
  commutingData, 
  noData, 
  loadingTimer, 
  pageViewId 
});

export default connect(mapStateToProps, { 
  setShowDetails, 
  setCommutingData,
  setNoData,
  setLoadingTimer
 })(
  SectionGettingAround
);

import { combineReducers } from 'redux';
import { 
  screenWidthReducer, 
  screenHeightReducer, 
  showMobileMenuReducer,
  loadingTimerReducer,
  noDataReducer,
  contentHeightReducer,
  pageViewIdReducer,
  fingerprintReducer,
  clientCountryReducer
 } from './browserReducers';
import { searchTermReducer, searchResponseReducer } from './formReducers';
import { 
  areaMetaDataReducer, 
  areaMetricsReducer,
  showSectionReducer, 
  houseDataReducer, 
  nurseriesDataReducer, 
  showMarkersReducer, 
  parksDataReducer,
  schoolsDataReducer,
  schoolGroupReducer,
  busStopsReducer,
  trainStationsReducer,
  chargersReducer,
  commutingDataReducer,
  affluenceDataReducer,
  demographicsDataReducer,
  diversityDataReducer,
  tenureAndHealthDataReducer,
  WorkerDataReducer,
  CrimeDataReducer 
} from './areaReducers';
import { 
  showModalReducer, 
  modalTypeReducer, 
  loginMessageReducer, 
  signupMessageReducer,
  requestPasswordModalReducer, 
  resetPasswordModalReducer,
  modalHeaderReducer,
  temporaryCredentialsReducer,
  showMessageModalReducer,
  messageModalContentReducer 
} from './modalReducers'
import { logStatusReducer, checkAuthReducer, userReducer } from './authReducers';


export default combineReducers({

  screenWidth: screenWidthReducer,
  screenHeight: screenHeightReducer,
  showMobileMenu: showMobileMenuReducer,
  searchTerm: searchTermReducer,
  searchResponse: searchResponseReducer,
  areaMetaData: areaMetaDataReducer,
  areaMetrics: areaMetricsReducer,
  showSection: showSectionReducer,
  houseData: houseDataReducer,
  nurseries: nurseriesDataReducer,
  showMarkers: showMarkersReducer,
  parks: parksDataReducer,
  schools: schoolsDataReducer,
  schoolGroup: schoolGroupReducer,
  busStops: busStopsReducer,
  trainStations: trainStationsReducer,
  chargers: chargersReducer,
  commutingData: commutingDataReducer,
  affluenceData: affluenceDataReducer,
  demographicsData: demographicsDataReducer,
  diversityData: diversityDataReducer,
  tenureAndHealthData: tenureAndHealthDataReducer,
  workerData: WorkerDataReducer,
  crimeData: CrimeDataReducer,
  showModal: showModalReducer,
  modalType: modalTypeReducer,
  logStatus: logStatusReducer,
  loginMessage: loginMessageReducer,
  signupMessage: signupMessageReducer,
  showResetPasswordModal: resetPasswordModalReducer, 
  showRequestPasswordModal: requestPasswordModalReducer,
  modalHeader: modalHeaderReducer,
  temporaryCredentials: temporaryCredentialsReducer,
  loadingTimer: loadingTimerReducer,
  noData: noDataReducer,
  contentHeight: contentHeightReducer,
  showMessageModal: showMessageModalReducer,
  messageModalContent: messageModalContentReducer,
  checkAuth: checkAuthReducer,
  pageViewId: pageViewIdReducer,
  fingerprint: fingerprintReducer,
  clientCountry: clientCountryReducer,
  user: userReducer
});

import {
    SET_SEARCH_TERM, 
    SET_SEARCH_RESULTS, 
    RETRIEVE_SEARCH_RESULTS, 
    CLEAR_SEARCH_RESULTS
} from '../actions/types';

export const searchTermReducer = (state="", action) => {
    switch(action.type) {
        case SET_SEARCH_TERM:
            return action.payload;
        default:
            return state;
    }
}

export const searchResponseReducer = (state={results: [], data_status: false}, action) => {
    switch(action.type) {
        case SET_SEARCH_RESULTS:
            return action.payload;
        case CLEAR_SEARCH_RESULTS:
            return {data_status: false, results: []};
        case RETRIEVE_SEARCH_RESULTS:
            const data = action.payload ? action.payload : []
            return data;
        default:
            return state;
    }
}
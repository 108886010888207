import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { InfoBanner } from '../elements/general';
import { BlueButton } from '../elements/formElements';
import { setContentHeight }from '../../actions';
import qcApi from '../../apis';
import history from '../../history';


const Collaborate = props => {

    const {fingerprint, user, contentHeight, screenHeight} = props
    const [containerHeight, setContainerHeight] = useState({})
    useEffect(() => {
        
        const screenContent = document.getElementById("collaborate_page").offsetHeight
        props.setContentHeight(screenContent)
        
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "collaborate"
            })
        }

        if(contentHeight > screenHeight) {
            setContainerHeight({})
        } else {
            setContainerHeight({height: screenHeight})
        }
    }, [fingerprint, contentHeight])  

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="Collaborate"
            subheading="Help us improve"/>
            <div className="ui container">
            <br/>
            <br/>
            <div 
                className="ui basic segment" 
                style={containerHeight} 
                id="collaborate_page">
                <div className="ui small header item image_item">
                    Are you a developer or have an interest in data visualisation in the urban research space
                </div>
                <p>
                    I am currently an individual that enjoys crunching and visualising data and have an interest in using this passion to provide information to my user community.   
                </p>
                <p>
                    While much of the data sourced on this project is publicly available, some our our inputs are sourced commercially. Additionally, the cost of making this information available is costly and funded entirely by me.  
                </p>
                <p>
                    As such, I'm always keen to here interested contributors to this projects in a number of capacities to improve this programme of work. Finally, I'm always keen to hear ideas and thoughts on how this website can improve. If you would like to connect to share any ideas, <span><a href="/contact">please get in touch.</a></span>
                </p>
              
                <br/>
                <BlueButton
                    label="Back to home"
                    onClick={() => history.push("/")}
                    icon="home icon"
                    className="icon"/>
                
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = ({
    fingerprint, 
    user,
    contentHeight,
    screenHeight
}) => ({
    fingerprint, 
    user,
    contentHeight,
    screenHeight
})

export default connect(mapStateToProps, {setContentHeight})(Collaborate)
import React, {useEffect} from "react";
import { connect } from "react-redux";
import { mobileScreenWidth, tabletScreenWidth } from "../constants";
import { setShowDetails, setAffluenceData, setNoData, setLoadingTimer } from "../actions";
import { AffluenceScatterChart, IncomeBarChart } from "./charts/affluenceCharts";
import { PercentileChart } from "./charts/PercentileChart";
import { ChartLoader } from './elements/general'

const AffluenceCharts = (props) => {
  const { data, locations, screenWidth } = props;
  const { scatter_data, income_data, deprivation_data } = data;
  const { district, ward, region } = locations;

  const locationName = ward ? ward : district

  if (!scatter_data) {
    return (
      <div className="ui basic segment center aligned">
          <ChartLoader/>
      </div>
      )
  }
  const showFull = screenWidth > tabletScreenWidth;

  const chartLabels = showFull ? (
    <>
      <div className="ui tiny blue header scatter_top_right">More affluent</div>
      <div className="ui tiny blue header scatter_bottom_left">
        Less affluent
      </div>
    </>
  ) : null;

  return (
    <>
      <div className="sixteen wide column main_chart_container">
        <div className="ui small header">
          {`Net income and qualification scores of ${locationName} wards against areas in the region and UK major cities`}
        </div>
        <AffluenceScatterChart
          data={scatter_data}
          screenWidth={screenWidth}
          showFull={showFull}
        />
        {chartLabels}
      </div>
      <div className="sixteen wide column no_margin_chart_container">
        <div className="ui small header">Average household income (000s)</div>
        <IncomeBarChart 
          data={income_data} 
          locations={locations} 
          screenWidth={screenWidth}
          />
      </div>
      <div className="sixteen wide column short_chart_container">
        <div className="ui small header">
          Deprivation percentile (England and Wales)
        </div>
        <PercentileChart
          data={deprivation_data}
          locations={{ locationName, region }}
        />
      </div>
    </>
  );
};

const SectionAffluence = props => {
  const {
    loadingTimer,
    screenWidth,
    showSection,
    affluenceData,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country,
      district_code,
      local_authorities
    },
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({affluence: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({affluence: false})
        clearTimeout(loadingTimer)
    })
  }, [ward, district_code])

  if(!ward && !district_code) {
      return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
      )
  }

  const {data_status} = affluenceData
  const focused = showSection.affluence;
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };

  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ affluence: false });
    } else {
      props.setShowDetails({ affluence: true }, pageViewId);
      if(!data_status) {props.setAffluenceData(locationId);}
    }
  };

  return (
    <div className="ui blue segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">Affluence</div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <AffluenceCharts
            data={affluenceData}
            locations={locations}
            screenWidth={screenWidth}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  affluenceData,
  areaMetaData,
  noData,
  loadingTimer,
  pageViewId
}) => ({ 
  screenWidth, 
  showSection, 
  affluenceData, 
  areaMetaData, 
  noData, 
  loadingTimer,
  pageViewId
});

export default connect(mapStateToProps, { 
  setShowDetails, 
  setAffluenceData,
  setNoData,
  setLoadingTimer
})(
  SectionAffluence
);

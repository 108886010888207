import React from 'react';
import { connect } from 'react-redux';
import MultiMarkers from '../MultiMarkers';
import { parksIcon } from '../../resources';

const MarkersParks = props => {
    const {parks, showMarkers, name} = props
    if(!showMarkers[name]) {return null}
    
    const locations = parks.map(item => {

        const popup = (
        <>
        <p>Category: {item.category}</p>
        <p>Link: {item.url}</p>
        <p>Area {item.area}m2</p>
        </>
        )
        return ({
            coords: [item.lat, item.lon],
            tooltip: item.name,
            popup
            })
        });
    

    return(
        <MultiMarkers
            data={locations}
            icon={parksIcon}
        />
    )
}

const mapStateToProps = ({showMarkers, parks}) => {
    return ({showMarkers, parks})
}

export default connect(mapStateToProps, {})(MarkersParks)
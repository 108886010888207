import React, {useEffect} from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import history from '../history';
import { setMessageModal, setMessageModalContent } from '../actions';

let MessageModal = props => {

    const {showMessageModal, messageModalContent } = props
    console.log(showMessageModal)
    useEffect(() => {
        if(!showMessageModal || messageModalContent.heading === "") {
            history.push("/")
            props.setMessageModal(false)
            props.setMessageModalContent("")
        }
    }, [showMessageModal])

    const onHideClick = () => {
        window.location.replace("/")
        props.setMessageModal(false)
        props.setMessageModalContent("")
    }
    
    return(
        <Modal
            closeIcon
            centered
            onClose={onHideClick}
            size="small"
            closeOnEscape
            closeOnDimmerClick
            open={showMessageModal}>
            <Header>{messageModalContent.heading}</Header>
            <Modal.Content>
                    <Header textAlign="center">
                        {messageModalContent.subheading}
                    </Header>
                    {messageModalContent.body}
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = ({ modalType, screenWidth, showMessageModal, messageModalContent}) => ({ modalType, screenWidth, showMessageModal, messageModalContent})

MessageModal = connect(mapStateToProps, {
    setMessageModal, setMessageModalContent
})(MessageModal)

export default MessageModal;
import qcApi, { ipGeolocationApi } from '../apis';
import { v4 as uuidv4 } from 'uuid';
import { ipGeolocationKey, serverUrl } from '../constants';

export const retrieveAccessToken = async authRequestId => {
    
    // const cookies = document.cookie.split(";")
    // const authCookie = cookies.find(cookie => cookie.includes("auth_request_id"))
    // const authRequestId = authCookie.split("=")[1]
    const response = await qcApi.post("/access-token", {
        data: {authRequestId}
    })
    const {data: {access_token}} = response
    return access_token
}

export const clearCookies = () => {
    const cookies = document.cookie.split(";")
    cookies.map(cookie => {
        const key = cookie.split("=")
        document.cookie = `${key[0]}=`
        return cookie
    })
}

export const getAuthRequestId = () => {
    if (document.cookie.includes("=")) {
        try {
            const cookies = document.cookie.split(";")
            const authCookie = cookies.find(cookie => cookie.includes("auth_request_id"))
            const authRequestId = authCookie.split("=")[1]
            return authRequestId
        } catch (err) {
            console.log(err)
        }
    }
}

export const refreshAuthRequestId = () => {
    document.cookie = "auth_request_id="
}

export const getAccessToken = () => {
    try {
        if (document.cookie.includes("=")) {
            const cookies = document.cookie.split(";")
            const accessCookie = cookies.find(cookie => cookie.includes("access_token"))
            const access_code = accessCookie.split("=")[1]
            return access_code
        }
    } catch (err) {
        console.log(err)
    }
}

export const accessTokenExpired = () => {
    try {
        if(document.cookie.includes("expires")) {
            const cookies = document.cookie.split(";")
            const expiryCookie = cookies.find(cookie => cookie.includes("expires"))
            const expiryDate = expiryCookie.split("=")[1]
            const expiry = Date.parse(expiryDate)
            const now = new Date().getTime()
            const expired = now > expiry
            return expired
        } else {
            return true
        }
    } catch (err) {
        console.log(err)
        return true
    }
}

export const authenticate = async () => {
    const accessToken = getAccessToken()
    const response = qcApi.post("/authenticate", {
        data: {accessToken}
    })
    return response
}

export const setAccessTokenCookie = token => {
    const date = new Date()
    const now = date.getTime() 
    const expiry = now + (1000 * 3600 * 24 * 7)
    date.setTime(expiry)
    document.cookie = `access_token=${token};expires=${date}`
}

export const onLogout = async () => {
    const accessToken = getAccessToken()
    const response = await qcApi.post("/logout", {
        data: accessToken
    })
    if(!response.data.logged_in) {
        clearCookies()
    }
    return response
}

export const onSignup = async values => {

    const locationData = await ipGeolocationApi.get("", {
        params: {apiKey: ipGeolocationKey}
    })
    const country = locationData.data.country_code3
    const requestId = uuidv4()
    const user = {...values, country, requestId}

    const response = await qcApi.post("/signup", user, {
        headers: {"Content-Type": "multipart/form-data"}
    })
    
    return response.data
}

export const onLogin = async values => {

    const locationData = await ipGeolocationApi.get("", {
        params: {apiKey: ipGeolocationKey}
    })
    const country = locationData.data.country_code3
    const requestId = uuidv4()
    const user = {...values, country, requestId}
    const response = await qcApi.post("/login", user, {
        headers: {"Content-Type": "multipart/form-data"}
    })

    return response.data
}

export const onGoogleAuth = async () => {
    const response = await ipGeolocationApi.get("", {
        params: {apiKey: ipGeolocationKey}
    })
    const country = response.data.country_code3
    const requestId = uuidv4()
    const params = {requestId, country}
    const queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
    document.cookie = `auth_request_id=${requestId}`
    const url = `${serverUrl}/api/google-auth`;
    window.location.replace(`${url}?${queryString}`);
}

export const checkDuplicateKeys = async (value, field) => {

    const response = await qcApi.get("/duplicate-keys", {
        params: {value, field}
    })

    return response.data
} 

export const requestTemporaryPassword = async email => {

    const response = await qcApi.post("/request-password", email, {
        headers: {"Content-Type": "multipart/form-data"}
    })

    return response
}

export const resetPassword = async values => {

    const locationData = await ipGeolocationApi.get("", {
        params: {apiKey: ipGeolocationKey}
    })
    const country = locationData.data.country_code3
    const requestId = uuidv4()
    const user = {...values, country, requestId}

    const response = await qcApi.post("/reset-password", user, {
        headers: {"Content-Type": "multipart/form-data"}
    })

    return response
}
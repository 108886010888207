import React from 'react';
import { Form } from 'semantic-ui-react'

export const renderError = (field, error, touched, duplicate) => {

    const errorMessage = duplicate
    ? `${field} already in use. Please try another`
    : error
    if(touched && error) {
        return <>
        <div className={`ui negative message`}>
        <div className="header">{`${field} error`}</div>
        <p>{errorMessage}</p>
      </div>
      <br/>
      </>
    }
}

export const TextField = props => {
    
    const {label, input, meta: {error, touched}, classTag, duplicate} = props
    
    const valueError = error || duplicate

    return <Form.Field className={classTag} style={{margin: "auto"}}>
        <input 
            {...input} 
            autoComplete="off" 
            placeholder={label} 
            value={input.value}
            style={{borderColor: "lightgray"}}/>
        {renderError(label, valueError, touched, duplicate)}
        </Form.Field>
    };

export const TextAreaField = ({label, input, meta: {error, touched}, classTag}) => {
    return <div className={classTag}>
        <textarea {...input} autoComplete="off" placeholder={label} />
        {renderError(label, error, touched)}
        </div>
    };

export const PrefilledTextField = props => {
    
    const {label, input, classTag, prefilled} = props
    
    return <Form.Field className={classTag} style={{margin: "auto"}}>
        <input 
            {...input} 
            autoComplete="off" 
            placeholder={label} 
            value={prefilled}
            style={{borderColor: "lightgray", backgroundColor: "#F2F3F4"}}/>
        
        </Form.Field>
    };

export const PasswordField = ({label, input, meta: {error, touched}, classTag}) => {  
    
    return <Form.Field className={classTag} style={{margin: "auto"}}>
        <input 
            {...input} 
            autoComplete="off" 
            type="password" 
            placeholder={label}
            style={{borderColor: "lightgray"}}/>
        {renderError(label, error, touched)}
        </Form.Field>
    };
export const PrefilledPasswordField = ({label, input, meta: {error, touched}, classTag, prefilled}) => {  
    
    const placeholder = prefilled ? prefilled : input.value
    return <Form.Field className={classTag} style={{margin: "auto"}}>
        <input 
            {...input} 
            autoComplete="off" 
            type="password" 
            placeholder={label}
            style={{borderColor: "lightgray", backgroundColor: "#F2F3F4"}}
            value={placeholder}/>
        {renderError(label, error, touched)}
        </Form.Field>
    };

export const SubmitButton = ({buttonText, classTag, reset, disabled, style}) => {

    return (
        <button 
            className={classTag} 
            type="submit"
            disabled={disabled}
            reset={reset}
            style={style}>
            {buttonText}
        </button>
    )
}

export const GoBackButton = ({classTag, buttonText, onClickCallback}) => {
    return (
        <button className={`${classTag}`} type="button" onClick={onClickCallback}>
            {buttonText}
        </button>
    )
}

export const YellowButton = props => {

    return(
        <div 
            className="ui icon button"
            style={{backgroundColor: "#EABD64", color: "#111D4A"}}
            onClick={props.onClick}>
            {props.label} &nbsp;
            <i className={props.icon}/>
        </div>
    )
}

export const BlueButton = props => {
    return(
        <div 
            className={`ui button ${props.className}`}
            style={{backgroundColor: "#111D4A", color: "#FFF"}}
            onClick={props.onClick}>
            {props.label} &nbsp;
            <i className={props.icon}/>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from "../history";
import { mobileScreenWidth } from '../constants';
import { 
    retrieveAccessToken, 
    refreshAuthRequestId, 
    getAuthRequestId,
    authenticate,
    setAccessTokenCookie, 
    clearCookies, onLogout
} from '../functions/auth';

import {setViewport, 
    setShowMobileMenu, 
    setShowModal, 
    setModalType,
    setLogStatus,
    setLoginMessage, 
    setSignupMessage,
    setCheckAuth,
    setFingerprint,
    setClientCountry
} from '../actions'
import { MainMenu } from '../subcomponents/NavBarElements';

const Navigation = props => {
    
    const {
        setViewport, 
        screenWidth, 
        showMobileMenu, 
        logStatus, 
        checkAuth
    } = props

    const [menuIcon, setMenuIcon] = useState("bars")
    const fullLogo = "https://imagedelivery.net/vwSu9FPUDFstEEs0kvSDNg/e81d1b9e-970e-42e0-6d1e-b750c3b2fa00/public"
    const smallLogo = "https://imagedelivery.net/vwSu9FPUDFstEEs0kvSDNg/ecb877ac-c016-4abe-85cc-07c4aed1ea00/public"
    const homeLink = screenWidth < mobileScreenWidth 
    ? {logo: smallLogo, size: "small"} 
    : {logo: fullLogo, size: "medium"}

    document.addEventListener("click", e => {
        if(!e.target.className.includes("bars")) {props.setShowMobileMenu(false)}
    })
    
    const updateScreenData = () => {
        setViewport([window.innerWidth, window.innerHeight])
    }

    let renderCycle = 0

    useEffect(() => {

        props.setFingerprint()
        props.setClientCountry()
        
        if (renderCycle === 0) {updateScreenData()}
        renderCycle++
        window.addEventListener('resize', updateScreenData)

        if(showMobileMenu) {
            setMenuIcon("x")
        } else {
            setMenuIcon("bars")
        }
        
        const requestId = getAuthRequestId()
        
        if(requestId) {
            props.setCheckAuth(false)
            retrieveAccessToken(requestId).then(token => {
                setAccessTokenCookie(token)
                refreshAuthRequestId()
                props.setLogStatus(true)
            })
            setTimeout(() => props.setCheckAuth(true), 3000)
        } else if (checkAuth && !requestId) {
            authenticate().then(res => {
                const {data : {access_token, logged_in}} = res
                props.setLogStatus(logged_in)

                if(!logged_in) {
                    clearCookies()
                    props.setLogStatus(logged_in)
                } else {
                    setAccessTokenCookie(access_token)
                }
            })
        }
        
    },[screenWidth, checkAuth, showMobileMenu, logStatus])
    
    const onLoginClick = () => {
        props.setModalType("login")
        props.setShowModal(true)
        props.setLoginMessage("Welcome back")
        props.setSignupMessage("Please sign up")
        history.push("/login")
    }

    const onLogOutClick = () => {
        onLogout().then(res => {
            const {data: {logged_in}} = res
            if(!logged_in) {
                props.setLogStatus(false)
            }
        })
        history.push(window.location.pathname)
    }
    
    return (
        <div className="custom_nav custom_nav_fixed">
            <div className="ui container custom_nav">
                <div className="ui basic segment custom_vertical_center">
                    {showMobileMenu 
                    ? <MainMenu showMenu={props.setShowMobileMenu}/> 
                    : null}
                    <div className="ui three column grid">
                        <div className="column middle aligned content">
                            <div 
                                className="ui icon inverted button" 
                                id="menu_button"
                                onClick={() => props.setShowMobileMenu(!showMobileMenu)}>
                                <i className={`${menuIcon} icon large`}/>
                            </div>
                        </div>
                        <div className="column center aligned custom_heading" > 
                            <Link id="heading" to="/" >
                                <img className={`ui ${homeLink.size} image`} src={homeLink.logo.toString()} alt="hoodchecker logo"/>
                            </Link>
                        </div>
                        <div className="column middle aligned content">
                            <div 
                                className="ui right floated inverted compact button"
                                onClick={logStatus ? onLogOutClick : onLoginClick}
                                title={logStatus ? "Log out" : "Log in"}>
                                {logStatus ? "Log out" : "Log in"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {

    const {
        screenWidth, 
        screenHeight, 
        showMobileMenu,
        showModal, modalType, logStatus, checkAuth
    } = state

    return ({
        screenWidth, 
        screenHeight, 
        showMobileMenu, 
        showModal, 
        modalType,
        logStatus, checkAuth
    }
    )}


export default connect(mapStateToProps, {
    setViewport, 
    setShowMobileMenu,
    setShowModal,
    setModalType,
    setLogStatus,
    setLoginMessage,
    setSignupMessage,
    setCheckAuth,
    setFingerprint,
    setClientCountry
})(Navigation)
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  mobileScreenWidth,
  smallScreenWidth,
  mediumScreenWidth,
} from "../constants";
import { setShowDetails, setDemographicsData, setNoData, setLoadingTimer } from "../actions";
import { AgeBrushChart } from "./charts/demographicsCharts";
import { DoublePieChart } from "./charts/DoublePieChart";
import { maritalStatusLabels, ageBracketLabels } from "../functions/labels";
import { ChartLoader } from './elements/general'

const DemographicsCharts = (props) => {
  const {
    data,
    locations: { ward, country, district },
    screenWidth,
  } = props;

  const locationName = ward ? ward : district
  const { age_data, marital_data } = data;

  if (!age_data || !marital_data) {
    return (
      <div className="ui basic segment">
        <ChartLoader/>
      </div>
    );
  };
  const colWidth = screenWidth < smallScreenWidth ? "sixteen" : "eight";
  const showFull = screenWidth > mediumScreenWidth;
  
  return (
    <>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">Resident marital status</div>
        <DoublePieChart 
          data={marital_data} 
          labelCallback={maritalStatusLabels} 
          showFull={showFull}/>
        <div className="inner_pie_label">{locationName}</div>
        <div className="outer_pie_label">{country}</div>
      </div>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">Age demographics</div>
        <AgeBrushChart 
          data={age_data} 
          locations={{ ward, district, country }} 
          labelCallback={ageBracketLabels}/>
      </div>
    </>
  );
};

const SectionDemographics = (props) => {
  const {
    loadingTimer,
    screenWidth,
    showSection,
    demographicsData,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country,
      district_code, 
      local_authorities 
    },
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({demographics: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({demographics: false})
        clearTimeout(loadingTimer)
    })
  }, [ward, district_code])

  if(!ward && !district_code) {return (
    <div className="ui basic segment">
      <ChartLoader/>
    </div>
  )}

  
  const {data_status} = demographicsData
  const focused = showSection.demographics;
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };

  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ demographics: false });
    } else {
      props.setShowDetails({ demographics: true }, pageViewId);
      if(!data_status) {props.setDemographicsData(locationId)};
    }
  };

  return (
    <div className="ui blue segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">
            Civil status and age demographics
          </div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <DemographicsCharts
            data={demographicsData}
            locations={locations}
            screenWidth={screenWidth}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  demographicsData,
  areaMetaData,
  pageViewId,
  noData,
  loadingTimer
}) => ({ 
  screenWidth, 
  showSection, 
  demographicsData, 
  areaMetaData,
  pageViewId,
  noData,
  loadingTimer
});

export default connect(mapStateToProps, {
  setShowDetails,
  setDemographicsData,
  setNoData,
  setLoadingTimer
})(SectionDemographics);

import React from 'react';
import {Form, Field} from 'react-final-form';
import { Modal, Header, Form as FormComponent } from 'semantic-ui-react';
import { connect } from 'react-redux';
import history from '../history';
import { 
    setShowModal, 
    setRequestPasswordModal, 
    setModalHeader, 
    setResetPasswordModal, 
    setModalType,
    setSignupMessage,
    setTemporaryCredentials,
} from '../actions';
import { validEmail } from '../functions';
import { requestTemporaryPassword } from '../functions/auth'
import { TextField, SubmitButton, GoBackButton } from '../subcomponents/elements/formElements';

let RequestPasswordModal = props => {

    const {screenWidth, showRequestPasswordModal } = props

    const showFull = screenWidth > 767
    const fullDimensions = {width: "eight", break: null}
    const partialDimensions = {width: "sixteen", break: <br/>}
    const dimensions = showFull ? fullDimensions : partialDimensions

    const onHideClick = () => {
        props.setRequestPasswordModal(false)
    }
    
    const onFormSubmit = async formValues => {
        
        const email = {email: formValues.email}

        const {data} = await requestTemporaryPassword(email) 
        
        if (data.existing_user) {
            const {email} = data
            props.setTemporaryCredentials({email, password: null})
            props.setModalHeader("Please check your email for a temporary password")
            props.setShowModal(false)
            props.setRequestPasswordModal(false)
            props.setResetPasswordModal(true)
            props.setModalType("/reset")
            history.push("/reset")
        } else {
            props.setRequestPasswordModal(false)
            props.setSignupMessage("It doesn't look like you've registered. Please sign up")
            history.push("/signup")
        }

        
    }

    const validate = formValues => {

        const errors = {}
        
        if(!validEmail.test(formValues.email) && formValues.email) {
            errors.email = "You must enter a valid email address"
        }

        return errors
    }
    
    return(
        <Modal
            closeIcon
            centered
            onClose={onHideClick}
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={showRequestPasswordModal}>
            <Header>Request a new password</Header>
            <Modal.Content>
                    <Header textAlign="center">
                        Please enter your email
                    </Header>
                    <Form 
                        validate={validate} 
                        validateOnBlur
                        onSubmit={values => onFormSubmit(values)}
                        render={({handleSubmit, pristine, submitting, form}) => (
                            <FormComponent onSubmit={handleSubmit}>
                                <br/>
                                <div className="fields">
                                    <Field 
                                        name="email" 
                                        component={TextField} 
                                        label="Email" autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}
                                        afterSubmit={() => form.reset()}/>
                                </div>
                                {dimensions.break}
                                <div className="ui grid" >
                                    <div 
                                        className={`${dimensions.width} wide column`} 
                                        style={{margin: "auto"}}>
                                        <GoBackButton
                                            classTag={`ui primary left floated button`}
                                            buttonText="Back"
                                            onClickCallback={onHideClick}
                                            />
                                    </div>
                                    <div 
                                        className={`${dimensions.width} wide column`} 
                                        style={{margin: "auto"}}>
                                        <SubmitButton
                                            classTag={`ui primary right floated button`}
                                            buttonText="Submit"
                                            disabled={submitting || pristine}
                                            onClick={() => form.reset}
                                            />
                                    </div> 
                                </div>
                            </FormComponent>
                        )}/>
            </Modal.Content>


        </Modal>
    )
}

const mapStateToProps = ({ modalType, screenWidth, showRequestPasswordModal}) => ({ modalType, screenWidth, showRequestPasswordModal})

RequestPasswordModal = connect(mapStateToProps, {
    setShowModal,
    setRequestPasswordModal,
    setModalHeader,
    setResetPasswordModal,
    setModalType,
    setSignupMessage,
    setTemporaryCredentials
})(RequestPasswordModal)

export default RequestPasswordModal
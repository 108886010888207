export const mobileScreenWidth = 500
export const smallScreenWidth = 600
export const tabletScreenWidth = 700
export const mediumScreenWidth = 800
export const largeScreenWidth = 1000
export const hugeScreenWidth = 1200
export const sections = {
    housing: false,
    families: false,
    schools: false,
    gettingAround: false,
    affluence: false,
    diversity: false,
    crime: false
}

export const chartColours = [
    ["#92D4FB", "#FFD17B", "#A9D08E", "#F8CBAD"],
    ["#111D4A", "#B1740D", "#548235", "#C65911"]
]

export const contentHeightBuffer = 200

export const gradientChartColours = [
    "#f8d288",
    "#e5c383",
    "#d2b47d",
    "#ac9672",
    "#99876d",
    "#736862",
    "#60595d",
    "#4d4a57",
    "#272c4c",
    "#141d47"
]

export const ipGeolocationKey = "e7e0a1b8923641389992297ebcd334d5"

export const serverUrl = window.location.href.includes("localhost")
? "http://127.0.0.1:5000"
: "https://hoodchecker.com"

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams} from 'react-router-dom';
import { 
    retrieveSearchResults, 
    setLoadingTimer, 
    setNoData, 
    setContentHeight,
    setAreaViewId,
    clearSearchResults 
} from '../actions';
import { formatNumToLocalString } from '../functions';
import { NavBuffer } from '../subcomponents/NavBarElements';
import { TextLoader } from '../subcomponents/elements/general';
import { BlueButton } from '../subcomponents/elements/formElements';
import history from '../history';
import qcApi from '../apis';


const SearchResults = props => {

    const {clientCountry, fingerprint, contentHeight, noData, loadingTimer, user, searchResponse: {
        results, 
        data_status,
        search_id,
        search_term
    }} = props
    
    const noSearchResults = (
        <div>
            No search results found
            <br/>
            <br/>
            <BlueButton 
                onClick={() => window.location.replace("/")}
                label="Search again"
                icon="search icon"/>
        </div>
    )
    
    const resultsHeader = noData.search || results.length === 0 
    ? noSearchResults
    : "Search results"
    const renderLoader = noData.search ? null : <TextLoader/>

    const params = useParams()
    useEffect(() => {

        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "search"
            })
        }

        const screenContent = document.getElementById("search_content")
        props.setContentHeight(screenContent.offsetHeight - 768)

        if(!data_status) {
            props.retrieveSearchResults(params.searchTerm)
            const timer = setTimeout(() => props.setNoData({search: true}), 5000)
            props.setLoadingTimer(timer)
        }

        return (() => {
            props.setNoData({search: false})
            clearTimeout(loadingTimer)            
        })
    }, [data_status, contentHeight, fingerprint])



    const renderSearchResults = results.length === 0
    ? null
    : results.map(result => {
        const {
            ward, 
            local_auth, 
            residents, 
            description, 
            id,
            district_code,
            local_authorities
        } = result

        const heading = ward 
        ? `${ward} (${local_auth})`
        : `${district_code}`
        
        const areaBioParam = ward ? id : district_code
        
        const subheading = () => {
            if(ward) {
                return `Population: ${formatNumToLocalString(residents)}`
            } else {
                return local_authorities.map((item, index) => {

                    if(index + 1 === local_authorities.length) {
                        return <span key={index}>{`${item}`}</span>
                    } else {
                        return <span key={index}>{`${item}, `}</span>
                    }
                })}}
        
        const selectAreaBio = async () => {
            
            await props.setAreaViewId(search_id, search_term, areaBioParam, fingerprint, clientCountry)
            history.push(`/areabio/${areaBioParam}`)
        }
        
        
        return (
            <div 
                key={result.id} 
                className="ui segment clickable_text" 
                onClick={selectAreaBio}>
                <a className="ui small header" href={`/areabio/${areaBioParam}`}>
                    {heading}
                </a>
                <BlueButton 
                    
                    label="Go"
                    className="right floated"/>
                <div className="ui tiny header">
                    {subheading()}
                </div>
                <p className="paragraph">{description}</p>
            </div>
        )
    })

    return (
        <div className="ui container" id="search_content">
            <br/>
            <br/>
            <NavBuffer/>
            <div className="ui basic segment">
                <div className="ui massive header">{resultsHeader}</div>
            </div>
            {data_status ? renderSearchResults : renderLoader}
            <br/>
            <br/>
        </div>

    )
}

const mapStateToProps = ({
    searchResponse, 
    loadingTimer, 
    noData,
    contentHeight,
    fingerprint,
    clientCountry,
    user
}) => ({
    searchResponse, 
    loadingTimer, 
    noData,
    contentHeight,
    fingerprint,
    clientCountry,
    user
})

export default connect(mapStateToProps, {
    retrieveSearchResults, 
    setLoadingTimer, 
    setNoData,
    setContentHeight,
    setAreaViewId,
    clearSearchResults
})(SearchResults)
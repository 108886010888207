import React from 'react';
import { 
    ResponsiveContainer,
    Bar,
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Legend,
    Tooltip,
    Cell, 
    ComposedChart,
    LabelList
} from 'recharts';
import { mobileScreenWidth, smallScreenWidth, tabletScreenWidth, chartColours} from '../../constants';
import { formatToThousands, roundTwoDecimals, responsiveBarChart, xAxisLabels, formatToThousandsOneDecimal, formatNumToLocalString} from '../../functions';

export const AffluenceScatterChart = props => {
    const {data, screenWidth, showFull} = props

    const chartHeight = screenSize => {
        if(screenSize > tabletScreenWidth) {return 500} 
        else if(screenSize < tabletScreenWidth && screenSize > smallScreenWidth) {return 400}
        else if(screenSize < smallScreenWidth && screenSize > mobileScreenWidth) {return 350}
        else {return 300}
    }
    
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const qualScore = roundTwoDecimals(payload[0].value)
            const incomeValue = formatNumToLocalString(payload[1].value)
            return (
            <div >
                <div className="ui tiny header">{`${payload[2].payload.location}`}</div>
                <p className="label">{`Qualification score : ${qualScore}`}</p>
                <p className="intro">{`Net household income : ${incomeValue}`}</p>
            </div>
            )}}

    return (
        <ResponsiveContainer width="100%" height={chartHeight(screenWidth)}>
            <ScatterChart
                margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
                }}
            >
                <CartesianGrid />
                {/* <Label style={{textAnchor: "middle"}}/> */}
                <XAxis 
                    type="number" 
                    dataKey="qualification_score" 
                    name="qualification score" 
                    domain={["auto", "auto"]}
                    label={{value:"Qualification score", position: "bottom"}}
                    tickFormatter={roundTwoDecimals}
                    
                />
                <YAxis 
                    type="number" 
                    dataKey="net_income" 
                    name="net household income"  
                    domain={["auto", "auto"]}
                    tickFormatter={formatToThousands}
                    label={{
                        value: showFull ? "Net household income (000s)" : null, 
                        angle: -90}}
                >
                </YAxis>
                <ZAxis type="number" dataKey="z_index" range={[20, 100]} name="highlight" unit="unit" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip/>}/>
                <Scatter 
                    name="affluence rating" 
                    data={data} 
                    fill="#8884d8"
                >
                    {data.map((entry, index) => (
                    <Cell 
                        key={`cell-${index}`} 
                        fill={entry.colour} 
                    />
                    ))}
                </Scatter>

            </ScatterChart>
        </ResponsiveContainer>
    )
}



export const IncomeBarChart = props => {
    const {data, locations: {ward, district}, screenWidth} = props

    const locationName = ward ? ward : district

    const colours = [chartColours[1][0], chartColours[0][0]]
    const altColours = [chartColours[1][1], chartColours[0][1]]
    let upperValue = 0
    
    const incrementedValues = data.map((item, index) => {
        const {income_net, income_gross, income_upper, income_lower} = item

        upperValue = income_upper > upperValue ? income_upper : upperValue
        const increment = {...item, 
            income_gross: income_gross - income_net,
            delta: income_upper - income_lower,
            fullName: item.name,
            name: xAxisLabels(screenWidth, item.name)
        }
        return increment
    })
    const {
        xFontSize, 
        layout, 
        xType, 
        yType, 
        xKey, 
        yKey,
        xTickFormatter,
        yTickFormatter,
        yCustomLabelMargin
    } = responsiveBarChart(screenWidth, formatToThousands)
    
    const grossIncomeLabels = props => {
        const { x, y, width, index } = props;
        const grossIncome = formatToThousandsOneDecimal(data[index].income_gross)
        
        return (
            <text x={x + width/2} y={y + yCustomLabelMargin} fill={colours[0]} textAnchor="middle" dominantBaseline="middle">
                {grossIncome}
            </text>
        )
    }

    const CustomTooltip = (props) => {
        const {active, payload} = props
        
        if (active && payload && payload.length) {
            const fullName = payload[0].payload.fullName
            return (
            <div className="custom_tooltip" >
                <p className="label">{fullName}</p>
            </div>
            )}}
    
    return(
        <ResponsiveContainer width="100%" height="80%">
            <ComposedChart 
                data={incrementedValues} 
                margin={{top: 50, right: 30, left: 20, bottom: 10}} 
                layout={layout} >
                <XAxis 
                    dataKey={xKey} 
                    tick={{fontSize: xFontSize}} 
                    type={xType}
                    tickFormatter={xTickFormatter}
                />
                <YAxis 
                    tickFormatter={yTickFormatter} 
                    type={yType}
                    dataKey={yKey}
                />
                <Tooltip content={<CustomTooltip/>} />
                <Legend />  
                <Bar dataKey="income_net" stackId="a" fill={colours[0]} name="Net income" >
                    {data.map((entry, index) => (
                    <Cell 
                        key={`cell-${index}`} 
                        fill={entry.name === locationName ? altColours[0] : colours[0]} 
                    />
                    ))}
                    <LabelList 
                        dataKey="income_net" 
                        formatter={formatToThousandsOneDecimal} 
                        fill="white"/>
                </Bar>    
                <Bar dataKey="income_gross" stackId="a" fill={colours[1]} name="Gross income">
                    {data.map((entry, index) => (
                    <Cell 
                        key={`cell-${index}`} 
                        fill={entry.name === locationName ? altColours[1] : colours[1]} 
                    />
                    ))}
                    <LabelList dataKey="income_gross" content={grossIncomeLabels}/>
                </Bar>  
            </ComposedChart>
        </ResponsiveContainer>
    )
}
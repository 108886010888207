import React, {useState, useEffect} from 'react';
// import {Field, reduxForm} from 'redux-form'; //Deprecated
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Form as FormComponent } from 'semantic-ui-react';
import { TextField, TextAreaField, SubmitButton } from '../../subcomponents/elements/formElements';
import { InfoBanner } from '../../subcomponents/elements/general';
import { validEmail } from '../../functions'
import qcApi from '../../apis';
import { setMessageModalContent, setMessageModal } from '../../actions';
import history from '../../history';

let Contact = props => { 

    const {fingerprint, user, screenHeight, contentHeight} = props
    const [containerHeight, setContainerHeight] = useState({})
    
    useEffect(() => {
        
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "contact"
            })
        }

        if(contentHeight > screenHeight) {
            setContainerHeight({})
        } else {
            setContainerHeight({height: screenHeight})
        }
    }, [fingerprint, contentHeight])    

    const onFormSubmit = async formValues => {
        const {name, email, message} = formValues
        const formData = new FormData()
        formData.append("name", name)
        formData.append("email", email)
        formData.append("message", message)
        
        qcApi.post("/contact", formData, {
            headers: {"Content-Type": "multipart/form-data"}
        }).then(({data}) => {
            if(data.message === "success") {
                props.setMessageModalContent({
                    heading: "Thank you for contacting us",
                    body: "Your message has been sent and we look forward to getting back to you. "
                })
                props.setMessageModal(true)
                history.push("/message")
            } else {
                props.setMessageModalContent({
                    header: "Something went wrong",
                    body: "It doesn't look like your message made its way to us. Please try and resubmit the contact form. "
                })
                props.setMessageModal(true)
                history.push("/message")
            }
        })
    }

    const validate = formValues => {
        const errors = {}
        if(!formValues.name) {
            errors.name = "You must enter a valid name or username"
        }
    
        if (!formValues.email) {
            errors.email = "You must enter an email address"
        }
    
        if(!validEmail.test(formValues.email)) {
            errors.email = "You must enter a valid email address"
        }
    
        if (!formValues.message) {
            errors.message = "You must enter a message"
        }
        return errors
    }

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="Contact"
            subheading="I'm keen to hear from you"/>
            <div className="custom_contact_background_image" style={containerHeight}>
                <div className="ui container">
                    <div className="ui grid">
                        <div className={`sixteen wide column custom_about_body`}>
                            <div className="custom_about_body_text">
                                <div className={`sixteen custom_about_description_heading`}>
                                    Please get in touch
                                </div>
                                    <br/>
                                    <Form validate={validate} onSubmit={values => onFormSubmit(values)}>
                                    {({ handleSubmit, pristine, reset, submitting, form }) => (
                                        <FormComponent onSubmit={handleSubmit}>
                                            <div className="fields">
                                            <Field name="name" component={TextField} label="Name" classTag="sixteen wide required field"/>
                                            </div>
                                            <br/>
                                            <div className="fields">
                                            <Field name="email" component={TextField} label="Email" classTag="sixteen wide required field"/>
                                            </div>
                                            <br/>
                                            <div className="fields">
                                            <Field name="message" component={TextAreaField} label="Message" classTag="sixteen wide required field"/>
                                            </div>
                                            <br/>
                                            <SubmitButton
                                                classTag={`ui left floated button`}
                                                style={{backgroundColor: "#EABD64", color: "#111D4A"}}
                                                buttonText="Submit"
                                                disabled={submitting || pristine}
                                                onClick={() => form.reset()}
                                                />
                                        </FormComponent>
                                    )}
                                    </Form>
                                </div>
                                <br/>
                                <br/>
                        </div>
                    </div>
                </div>
                </div>

        </div>
    )
}

const mapStateToProps = ({
    fingerprint, 
    user, 
    screenHeight, 
    contentHeight
}) => ({
    fingerprint, 
    user, 
    screenHeight, 
    contentHeight
})

Contact = connect(mapStateToProps, {setMessageModalContent, setMessageModal})(Contact)

export default Contact
import React, {useEffect} from "react";
import { connect } from "react-redux";
import { tabletScreenWidth, mobileScreenWidth } from "../constants";
import { setShowDetails, setTenureAndHealthData, setNoData, setLoadingTimer } from "../actions";
import { SpiderChart } from "./charts/SpiderChart";
import { tenureLabels, healthLabels } from '../functions/labels';
import { ChartLoader } from './elements/general'

const OtherCharts = (props) => {
  const {
    data,
    screenWidth,
    locations: { ward, district, country },
  } = props;
  const { tenure_data, health_data } = data;
  if (!tenure_data || !health_data) {
    return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
    )
  }
  const colWidth = screenWidth < tabletScreenWidth ? "sixteen" : "eight";

  return (
    <>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">Housing tenure</div>
        <SpiderChart 
          data={tenure_data} 
          locations={{ ward, district, country }} 
          labelCallback={tenureLabels}/>
      </div>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">Resident health</div>
        <SpiderChart 
          data={health_data} 
          locations={{ ward, district, country }} 
          labelCallback={healthLabels}/>
      </div>
    </>
  );
};

const SectionOther = props => {
  const {
    loadingTimer,
    tenureAndHealthData,
    screenWidth,
    showSection,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country,
      district_code, 
      local_authorities  
    },
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({tenureAndHealth: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({tenureAndHealth: false})
        clearTimeout(loadingTimer)
    })
  }, [ward, district_code])

  if(!ward && !district_code) {
      return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
      )
  }

  const focused = showSection.tenureAndHealth;
  const {data_status} = tenureAndHealthData
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };

  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ tenureAndHealth: false });
    } else {
      props.setShowDetails({ tenureAndHealth: true }, pageViewId);
      if(!data_status) {props.setTenureAndHealthData(locationId)};
    }
  };

  return (
    <div className="ui violet segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">
            Home ownership and resident health
          </div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <OtherCharts
            data={tenureAndHealthData}
            screenWidth={screenWidth}
            locations={locations}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  areaMetaData,
  tenureAndHealthData,
  noData,
  loadingTimer,
  pageViewId
}) => ({ 
  screenWidth, 
  showSection, 
  areaMetaData, 
  tenureAndHealthData, 
  noData,
  pageViewId,
  loadingTimer 
});

export default connect(mapStateToProps, {
  setShowDetails,
  setTenureAndHealthData,
  setNoData,
  setLoadingTimer
})(SectionOther);

import React from 'react';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'

export const TextLoader = () => (
    <div className="custom_loader">  
      <Segment basic>
        <Dimmer active inverted>
          <Loader inverted content='Loading' size="huge"/>
        </Dimmer>
  
        <Image src='https://cdn.boom.co/images/transformations/2585_uploaded_hny3oowusjprdtuvbrlu' />
      </Segment>
    </div>
  )

export const MapLoader = () => (
  <div className="custom_loader">  
    <Segment basic>
      <Dimmer active inverted>
        <Loader inverted content='Loading' size="huge"/>
      </Dimmer>

      <Image src='https://cdn.boom.co/images/transformations/2585_uploaded_xhgtsj8ozsygquxvtmah' />
    </Segment>
  </div>
)

export const ChartLoader = () => (
  <div className="custom_loader">  
    <Segment basic>
      <Dimmer active inverted>
        <Loader inverted content='Loading' size="huge"/>
      </Dimmer>

      <Image src='https://cdn.boom.co/images/transformations/2585_uploaded_mqtgxzrikgtdbgacqha3' />
    </Segment>
  </div>
)

export const InfoBanner = ({heading, subheading}) => {
  return (
    <div className="custom_about_header">
        <div className="ui container">
            <div className="custom_about_heading">{heading}</div>
            <div className="custom_about_subheading">{subheading}</div>
        </div>
    </div>
  )
}

export const NodataContainer = props => {
  return(
    <div className="ui container">
      <div className="ui placeholder segment">
        <div className="ui icon header">
          <i className={`${props.icon} icon`}></i>
          {props.text}
        </div>
      </div>
      <br/>
    </div>
  )
}
import React from 'react';
import {
    ResponsiveContainer, 
    BarChart, 
    Bar, 
    LineChart, 
    CartesianGrid, 
    Line, 
    XAxis, 
    YAxis, 
    Tooltip, 
    Legend
} from 'recharts';
import { formatNumToLocalString } from '../../functions';

export const HouseLineChart = props => {
    const {data, chartName, chartHeight, showFull, labels, ward} = props
    
    const subject = ward ? "ward" : "district"
    
    return (
        <div className="chart_container">
            <div className="ui small header">{chartName}</div>
            <ResponsiveContainer height={chartHeight}>
                <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                {showFull ? <YAxis tickFormatter={formatNumToLocalString} domain={[0, 'auto']}/> : null}
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={subject} stroke="#70AD47" strokeWidth={3} name={labels[subject]} activeDot={{ r: 8 }} dot={false} />
                <Line type="monotone" dataKey="local_authority" stroke="#B4C7E7" name={labels["local_authority"]} dot={false} />
                <Line type="monotone" dataKey="region" stroke="#8FAADC" name={labels["region"]} dot={false} />
                <Line type="monotone" dataKey="country" stroke="#8497B0" name={labels["country"]} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

}

export const HouseBarChart = props => {

    const {data, chartName, labels, showLegend, showFull, chartHeight, ward} = props

    const subject = ward ? "ward" : "district"
    
    return (
        <div className="chart_container">
            <div className="ui small header">{chartName}</div>
            <ResponsiveContainer height={chartHeight}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="name"/>
                    {showFull ? <YAxis tickFormatter={formatNumToLocalString}/> : null}
                    <Tooltip formatter={formatNumToLocalString}/>
                    <Bar dataKey={subject} fill="#70Ad47" name={labels[subject]} />
                    {showFull ? <Bar dataKey="local_authority" fill="#B4C7E7" name={labels["local_authority"]}/> : null}
                    <Bar dataKey="region" fill="#8FAADC" name={labels["region"]}/>
                    {showFull ? <Bar dataKey="country" fill="#8497B0" name={labels["country"]}/> : null}
                    {showLegend ? <Legend verticalAlign="top"/> : null}
                </BarChart> 
            </ResponsiveContainer>
        </div>
    )
}
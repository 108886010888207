import React from 'react';
import { 
    ComposedChart,
    Line,
    Area,
    Bar, 
    XAxis,
    YAxis,
    CartesianGrid,
    ReferenceLine,
    ResponsiveContainer,
    Legend,
    Tooltip 
} from 'recharts';
import { convertToPercentage, convertToPercentageDecimal } from '../../functions';
import {chartColours} from '../../constants';

export const AgeBrushChart = props => {
    const {data, locations: {ward, country, district}, labelCallback} = props

    const locationName = ward ? ward : district
    const inverseWard = locationName.concat("_inverse")
    const inverseCountry = country.concat("_inverse")
    const reversedData = [...data].reverse()
    const relabeledData = reversedData.map(item => ({...item, subject: labelCallback(item.subject)}))
    const colours = [chartColours[1][0], chartColours[0][3], chartColours[1][3]]
  
    const customLegend = [{subject: locationName, colour: "#413ea0"}, {subject: country, colour: "#ff7300"}]

    const CustomTooltip = (props) => {
      const {active, payload} = props
      if (active && payload && payload.length) {
        const comparatorLabel = payload[0].name
        const wardLabel = payload[2].name
        const comparatorValue = convertToPercentageDecimal(payload[0].value)
        const wardValue = convertToPercentageDecimal(payload[2].value)
        
        return (
        <div className="custom_tooltip" >
            <p>{wardLabel}: <span>{wardValue}</span></p>
            <p>{comparatorLabel}: <span>{comparatorValue}</span></p>
        </div>
        )}}
  
    return(
        <ResponsiveContainer width="100%" height="90%">
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={relabeledData}
          stackOffset="sign"
          margin={{
            top: 40,
            right: 20,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" domain={["auto", "auto"]} tickFormatter={convertToPercentage}/>
          <YAxis dataKey="subject" type="category" scale="band" />
          <ReferenceLine x={0} stroke="#000" />
          <Tooltip content={<CustomTooltip/>}/>
          <Legend
              payload={customLegend.map(
                (item, index) => ({
                  id: item.subject,
                  type: "square",
                  value: item.subject,
                  color: item.colour
                }))
              }
              align="center"
            />
          <Area dataKey={country} fill={colours[1]} stackId="stackCountry"/>
          <Area dataKey={inverseCountry} fill={colours[1]} stackId="stackCountry"/>
          <Bar dataKey={locationName} barSize={20} fill={colours[0]} stackId="stackWard"/>
          <Bar dataKey={inverseWard} barSize={20} fill={colours[0]} stackId="stackWard"/>
          <Line dot={false} dataKey={country} stroke={colours[2]} stackId="stackCountryLine"/>
          <Line dot={false} dataKey={inverseCountry} stroke={colours[2]} stackId="stackCountryLine"/>
        </ComposedChart>
      </ResponsiveContainer>
    )
}


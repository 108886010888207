import React, { useEffect } from "react";
import { connect } from "react-redux";
import { mobileScreenWidth, tabletScreenWidth } from "../constants";
import { setShowDetails, setCrimeData, setNoData, setLoadingTimer } from "../actions";
import { SpiderChart } from "./charts/SpiderChart";
import { majorCrimeLabels, theftCrimeLabels } from '../functions/labels';
import { ChartLoader } from './elements/general'

const CrimeCharts = (props) => {
  const {
    data,
    screenWidth,
    locations: { ward, region, district },
  } = props;
  const { major_crime, theft_crime } = data;
  
  if (!major_crime || !theft_crime ) {
    return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
    );
  }
  const colWidth = screenWidth < tabletScreenWidth ? "sixteen" : "eight";
  return (
    <>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">
          Non-theft crime per 1,000 residents
        </div>
        <SpiderChart 
          data={major_crime} 
          locations={{ ward, region, district }} 
          labelCallback={majorCrimeLabels}/>
      </div>
      <div className={`${colWidth} wide column main_chart_container`}>
        <div className="ui small header">Theft crime per 1,000 residents</div>
        <SpiderChart 
          data={theft_crime} 
          locations={{ ward, region, district }} 
          labelCallback={theftCrimeLabels}/>
      </div>
    </>
  );
};

const SectionCrime = props => {
  const {
    loadingTimer,
    screenWidth,
    showSection,
    crimeData,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country,
      district_code,
      local_authorities 
    },
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({crime: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({crime: false})
        clearTimeout(loadingTimer)
    })
  }, [ward, district_code])

  if(!ward && !district_code) {
      return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
      )
  }

  const {data_status} = crimeData
  const focused = showSection.crime;
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };


  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ crime: false });
    } else {
      props.setShowDetails({ crime: true }, pageViewId);
      if(!data_status) {props.setCrimeData(locationId)};
    }
  };

  return (
    <div className="ui grey segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">Crime and safety</div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <CrimeCharts
            data={crimeData}
            locations={locations}
            screenWidth={screenWidth}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  areaMetaData,
  crimeData,
  noData,
  loadingTimer,
  pageViewId
}) => ({ 
  screenWidth, 
  showSection, 
  areaMetaData, 
  crimeData, 
  noData, 
  loadingTimer,
  pageViewId
});

export default connect(mapStateToProps, { setShowDetails, setCrimeData, setNoData, setLoadingTimer })(
  SectionCrime
);

import React, {useEffect} from 'react';
import {Form, Field} from 'react-final-form';
import { Modal, Header, Form as FormComponent } from 'semantic-ui-react';
import { connect } from 'react-redux';
import history from '../history';
import { 
    setShowModal, 
    setModalType, 
    setLogStatus, 
    setLoginMessage, 
    setSignupMessage, 
    setResetPasswordModal,
    setTemporaryCredentials,
    setModalHeader
} from '../actions';
import { validPassword } from '../functions';
import { setAccessTokenCookie, resetPassword } from '../functions/auth';
import { PasswordField, SubmitButton, PrefilledTextField } from '../subcomponents/elements/formElements';

let ResetPasswordModal = props => {

    const {screenWidth, showResetPasswordModal, temporaryCredentials } = props

    const showFull = screenWidth > 767
    const fullDimensions = {width: "eight", break: null}
    const partialDimensions = {width: "sixteen", break: <br/>}
    const dimensions = showFull ? fullDimensions : partialDimensions
    const showTempPasswordField = temporaryCredentials.password ? false : true

    useEffect(() => {
        if(!temporaryCredentials.email) {
            history.push("/")
        }
    }, [temporaryCredentials.email])

    const onHideClick = () => {
        window.location.replace("/")
    }
    
    const onFormSubmit = async formValues => {

        const {email, password} = temporaryCredentials

        const temporaryPassword = formValues.temporaryPassword
        ? formValues.temporaryPassword
        : password

        const formData = {...formValues, email, temporaryPassword}
        const response = await resetPassword(formData)
        const {data} = response
        
        if(data.access_token) {
            props.setLogStatus(true)
            setAccessTokenCookie(data.access_token)
            history.push("/")
            props.setResetPasswordModal(false)
            props.setLoginMessage("Welcome back")
        } else if(data.existing_user) {
            props.setLoginMessage("Please check the password in your email and try again")
            history.push("/login")
        } else {
            props.setResetPasswordModal(false)
            props.setSignupMessage("Please sign up")
            props.setLoginMessage("Welcome back")
            history.push("/signup")
        }
        props.setTemporaryCredentials({})
        
    }
    
    const validate = formValues => {

        const errors = {}

        if(temporaryCredentials.password && !formValues.temporaryPassword) {
            errors.message = "Please enter the temporary password emailed to you"
        }

        if(!validPassword.test(formValues.newPassword) && formValues.newPassword) {
            errors.newPassword = "Please enter a valid password"
        }
        
        if(formValues.confirmPassword !== formValues.newPassword) {
            errors.confirmPassword = "Your passwords don't match"
        }
        

        return errors
    }
    
    return(
        <Modal
            closeIcon
            centered
            onClose={onHideClick}
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={showResetPasswordModal}>
            <Header>Request a new password</Header>
            <Modal.Content>
                    <Header textAlign="center">
                        Please enter your email
                    </Header>
                    <Form 
                        validate={validate} 
                        onSubmit={values => onFormSubmit(values)}
                        render={({handleSubmit, pristine, submitting, form}) => (
                            <FormComponent onSubmit={handleSubmit}>
                                <br/>
                                <div className="fields">
                                    <Field 
                                        name="email" 
                                        component={PrefilledTextField} 
                                        label="Email" autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}
                                        afterSubmit={() => form.reset()}
                                        prefilled={temporaryCredentials.email}/>
                                </div>
                                {dimensions.break}
                                {!showTempPasswordField ? null :
                                    <div className="fields">
                                    <Field 
                                        name="temporaryPassword" 
                                        component={PasswordField} 
                                        label="Temporary Password" 
                                        autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}
                                        afterSubmit={() => form.reset()}/>
                                </div>}
                                <div className="fields">
                                    <Field 
                                        name="newPassword" 
                                        component={PasswordField} 
                                        label="New Password" 
                                        autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}
                                        afterSubmit={() => form.reset()}/>
                                </div>
                                <div className="fields">
                                    <Field 
                                        name="confirmPassword" 
                                        component={PasswordField} 
                                        label="Confirm Password" 
                                        autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}
                                        afterSubmit={() => form.reset()}/>
                                </div>
                                <div className="ui grid" >
                                    <div 
                                        className={`${dimensions.width} wide column`} 
                                        style={{margin: "auto"}}>
                                        <SubmitButton
                                            classTag={`ui primary fluid button`}
                                            buttonText="Submit"
                                            disabled={submitting || pristine}
                                            onClick={() => form.reset()}
                                            />
                                    </div>
                                </div>
                            </FormComponent>
                        )}/>
            </Modal.Content>


        </Modal>
    )
}

const mapStateToProps = ({ modalType, screenWidth, showResetPasswordModal, temporaryCredentials}) => ({ modalType, screenWidth, showResetPasswordModal, temporaryCredentials})

ResetPasswordModal = connect(mapStateToProps, {
    setShowModal,
    setModalHeader,
    setResetPasswordModal,
    setModalType,
    setSignupMessage,
    setTemporaryCredentials,
    setLogStatus,
    setLoginMessage
})(ResetPasswordModal)

export default ResetPasswordModal;
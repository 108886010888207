import React from 'react';
import { 
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    Text,
    ResponsiveContainer,
    Legend,
    Tooltip 
} from 'recharts';
import { 
  roundTwoDecimals
} from '../../functions';
import { chartColours } from '../../constants';

export const SpiderChart = props => {
    const {data, locations: {district, ward, region, country}, labelCallback} = props
    
    const tickLabels = []
    const relabeledData = data.map(item => {
      const subject = item.subject
      const refinedSubject = labelCallback(subject)
      tickLabels.push(refinedSubject)
      return {...item, axisLabel: refinedSubject}
    })
    
    const locationName = ward ? ward : district
    const comparator = region ? region : country
    const colours = [chartColours[0][0], chartColours[0][3]]
    const strokeColours = [chartColours[1][0], chartColours[1][3]]

    const CustomTooltip = (props) => {
      const {active, payload} = props
      
      if (active && payload && payload.length) {
        const label = payload[0].payload.axisLabel
        const wardValue = roundTwoDecimals(payload[0].value)
        const comparatorValue = roundTwoDecimals(payload[1].value)
  
          return (
          <div className="custom_tooltip" >
              <p className="label">{label}</p>
              <p className="label">{`${locationName}: ${wardValue}`}</p>
              <p className="label">{`${comparator}: ${comparatorValue}`}</p>
          </div>
          )}}
    
    const CustomTick = ({ index, x, y, textAnchor, stroke, radius }) => {
      
      const axisLabel = tickLabels[index]
      const labelArray = axisLabel.split(" ")
      
      return (
        <g className="recharts-layer recharts-polar-angle-axis-tick">          
          {labelArray.map((item, textIndex) => (
            <Text
            key={textIndex}
            radius={radius}
            stroke={stroke}
            x={x}
            y={y + (textIndex * 16)}
            textAnchor={textAnchor}
            verticalAnchor="start">
             {item}
          </Text>
          ))}
        </g>
      );
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="52%" cy="43%" outerRadius="80%" data={relabeledData}>
                <PolarGrid/>
                <PolarAngleAxis 
                  tick={<CustomTick/>}
                  />
                <Radar 
                  name={comparator} 
                  dataKey={comparator} 
                  stroke={strokeColours[0]} 
                  fill={colours[0]} 
                  fillOpacity={0.4} />
                  
                <Radar 
                  name={locationName} 
                  dataKey={locationName} 
                  stroke={strokeColours[1]} 
                  fill={colours[1]} 
                  fillOpacity={0.4}/>
                <Legend/>
                <Tooltip content={<CustomTooltip/>} />
            </RadarChart>
        </ResponsiveContainer>
    )
}
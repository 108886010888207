import React from 'react';
import { ComposableMap, Geographies, Geography, Marker as Pin } from 'react-simple-maps';
import { ukMap } from '../../resources';

const UkMap = props => {

    const {centre} = props
    const {name, canvas, width, height, dimensions, scale} = ukMap
    
    return (
        <ComposableMap 
            id={name}
            projection="geoMercator"
            width={width}
            height={height}
            projectionConfig={{
                rotate: dimensions,
                scale
            }}
            >
            <Geographies geography={canvas} stroke="#666" strokeWidth={0.25}>
                {({geographies}) => geographies.map((geo, index) => {
                    return (
                    <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#AAA"
                    onClick={() => console.log("thanks for clicking")}
                    />
                )})}
            </Geographies>
            <Pin coordinates={centre}>
                <g
                    fill="none"
                    stroke="#FF5533"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(-12, -24)"
                    >
                    <circle cx="12" cy="10" r="3" />
                    <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>
          </Pin> 
        </ComposableMap>
    )
}

export default UkMap
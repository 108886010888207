import React from 'react';
import { Marker, Tooltip, Popup } from 'react-leaflet';

const MultiMarkers = props => {
    const {data, icon} = props

    return data.map((item, index) => {
        const {coords, popup, tooltip} = item
        
        return (
            <Marker position={coords} key={index} icon={icon}>
                <Tooltip>{tooltip}</Tooltip>
                <Popup>{popup}</Popup>
            </Marker>
        )
    })
    
}

export default MultiMarkers
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { mobileScreenWidth } from "../constants";
import { setShowDetails, setWorkerData, setNoData, setLoadingTimer } from "../actions";
import { SpiderChart } from "./charts/SpiderChart";
import { WaterfallChart } from "./charts/WaterfallChart";
import { MixedChart } from "./charts/MixedBarLineChart";
import { employmentLabels, industryLabels, occupationLabels } from '../functions/labels';
import { ChartLoader } from './elements/general';

const WorkerCharts = (props) => {
  const {
    data,
    screenWidth,
    locations: { ward, district, region, country },
  } = props;
  const { emp_data, occupation_data, industry_data } = data;

  if (!emp_data || !occupation_data || !industry_data) {
    return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
    );
  }
  return (
    <>
      <div className={`sixteen wide column main_chart_container`}>
        <div className="ui small header">Working status of residents</div>
        <SpiderChart 
          data={emp_data} 
          locations={{ ward, district, country }} 
          labelCallback={employmentLabels}/>
      </div>
      <div className={`sixteen wide column main_chart_container`}>
        <div className="ui small header">Industries served by residents</div>
        <WaterfallChart 
          data={industry_data} 
          labelCallback={industryLabels} 
          screenWidth={screenWidth}/>
      </div>
      <div className={`sixteen wide column main_chart_container`}>
        <div className="ui small header">Industries served by residents</div>
        <MixedChart
          data={occupation_data}
          locations={{ ward, district, region, country }}
          textToRemove="occ_"
          labelCallback={occupationLabels}
          screenWidth={screenWidth}
        />
      </div>
    </>
  );
};

const SectionWorkers = props => {
  const {
    loadingTimer,
    screenWidth,
    showSection,
    workerData,
    pageViewId,
    areaMetaData: { 
      geocode, 
      ward, 
      local_auth, 
      region, 
      country,
      district_code, 
      local_authorities  
    },
  } = props;

  useEffect(() => {
    if(!ward && !district_code) {
        const timer = setTimeout(() => props.setNoData({workers: true}), 5000)
        props.setLoadingTimer(timer)
    }

    return (() => {
        props.setNoData({workers: false})
        clearTimeout(loadingTimer)
    })
  }, [ward, district_code])

  if(!ward && !district_code) {
      return (
      <div className="ui basic segment">
          <ChartLoader/>
      </div>
      )
  }

  const focused = showSection.workerProfiles;
  const {data_status} = workerData
  const iconSize = screenWidth > mobileScreenWidth ? "big" : null;
  const icon = focused ? "minus" : "plus";

  const locationId = ward ? geocode : district_code
  const locationAuthName = ward ? local_auth : local_authorities[0]
  const countryName = ward ? country : country[0]
  const regionName = ward ? region : region[0]

  const locations = { 
    ward, 
    local_authority: locationAuthName, 
    region: regionName, 
    country: countryName,
    district: district_code 
  };

  const unhideContent = () => {
    if (focused) {
      props.setShowDetails({ workerProfiles: false });
    } else {
      props.setShowDetails({ workerProfiles: true }, pageViewId);
      if(!data_status) {props.setWorkerData(locationId)};
    }
  };

  return (
    <div className="ui violet segment">
      <div className="ui grid">
        <div className="fourteen wide column middle aligned">
          <div className="ui medium header">Worker profiles</div>
        </div>
        <div className="two wide column right aligned">
          <i
            className={`${icon} circle ${iconSize} icon`}
            onClick={unhideContent}
          />
        </div>
        {focused ? (
          <WorkerCharts
            data={workerData}
            locations={locations}
            screenWidth={screenWidth}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  screenWidth,
  showSection,
  areaMetaData,
  workerData, 
  noData, 
  loadingTimer,
  pageViewId
}) => ({ 
  screenWidth, 
  showSection, 
  areaMetaData, 
  workerData, 
  noData, 
  loadingTimer,
  pageViewId 
});

export default connect(mapStateToProps, { 
  setShowDetails, 
  setWorkerData,
  setNoData,
  setLoadingTimer
 })(
  SectionWorkers
);

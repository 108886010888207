import {
    SET_SHOW_MODAL, 
    SET_MODAL_TYPE, 
    SET_LOGIN_MESSAGE, 
    SET_SIGNUP_MESSAGE,
    SET_REQUEST_PASSWORD_MODAL,
    SET_RESET_PASSWORD_MODAL,
    SET_MODAL_HEADER,
    SET_TEMPORARY_CREDENTIALS,
    SET_MESSAGE_MODAL,
    SET_MESSAGE_MODAL_CONTENT
} from '../actions/types';

export const showModalReducer = (state=false, action) => {
    switch(action.type) {
        case SET_SHOW_MODAL:
            return action.payload;
        default:
            return state;
    }
}

export const modalTypeReducer = (state=null, action) => {
    switch(action.type) {
        case SET_MODAL_TYPE:
            return action.payload;
        default:
            return state;
    }
}

export const loginMessageReducer = (state = "Welcome back", action) => {
    switch(action.type) {
        case SET_LOGIN_MESSAGE:
            return action.payload
        default:
            return state
    }
}

export const signupMessageReducer = (state = "Please sign up", action) => {
    switch(action.type) {
        case SET_SIGNUP_MESSAGE:
            return action.payload
        default:
            return state
    }
}
export const requestPasswordModalReducer = (state = false, action) => {
    switch(action.type) {
        case SET_REQUEST_PASSWORD_MODAL:
            return action.payload
        default:
            return state
    }
}

export const resetPasswordModalReducer = (state = false, action) => {
    switch(action.type) {
        case SET_RESET_PASSWORD_MODAL:
            return action.payload
        default:
            return state
    }
}

export const modalHeaderReducer = (state = false, action) => {
    switch(action.type) {
        case SET_MODAL_HEADER:
            return action.payload
        default:
            return state
    }
}
export const temporaryCredentialsReducer = (state = {}, action) => {
    switch(action.type) {
        case SET_TEMPORARY_CREDENTIALS:
            return {...state, ...action.payload}
        default:
            return state
    }
}

export const showMessageModalReducer = (state = false, action) => {
    switch(action.type) {
        case SET_MESSAGE_MODAL:
            return action.payload
        default:
            return state
    }
}

export const messageModalContentReducer = (state = {}, action) => {
    switch(action.type) {
        case SET_MESSAGE_MODAL_CONTENT:
            return action.payload
        default: return state
    }
}
import React from 'react';
import { connect } from 'react-redux';
import MultiMarkers from '../MultiMarkers';
import { chargerIcon } from '../../resources';

const MarkersChargers = props => {
    const {chargers, showMarkers, name} = props
    if(!showMarkers[name] || !chargers) {return null}
    
    const locations = chargers.map(item => {

        const attribution = item.attribution ? <p>Ref: {item.attribution}</p> : null
        const status = item.status ? <p>Status: {item.status}</p> : null
        const method = item.connector_charge_method 
        ? <p>Charge Method: {item.connector_charge_method}</p> 
        : null
        const output = item.connector_output_kw 
        ? <p>Output (Kw): {item.connector_output_kw}</p> 
        : null

        const tooltip = (
            <>
            {attribution}
            {status}
            {method}
            {output}
            </>
        )
        return ({
            coords: [item.lat, item.lon],
            popup: item.location_type,
            tooltip
        })
    })
    
    

    return(
        <MultiMarkers
            data={locations}
            icon={chargerIcon}
        />
    )
}

const mapStateToProps = ({showMarkers, chargers}) => {
    return ({showMarkers, chargers})
}

export default connect(mapStateToProps, {})(MarkersChargers)
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { setShowModal, setModalType, setResetPasswordModal, setMessageModal } from '../actions';
import AccountTypeSelection from '../modals/AccountTypeSelection';
import ResetPasswordModal from '../modals/ResetPassword';
import MessageModal from '../modals/MessageModal';

const Modal = props => {
    
    useEffect(() => {
        const route = window.location.pathname.replace("/", "")
        props.setModalType(route)

    },[window.location.pathname])

    const {modalType} = props
    
    const selectModalPage = () => {
        switch(modalType) {
            case "login":
                props.setShowModal(true)
                return <AccountTypeSelection/>
            case "signup":
                props.setShowModal(true)
                return <AccountTypeSelection/>
            case "reset":
                props.setResetPasswordModal(true)
                return <ResetPasswordModal/>
            case "message":
                return <MessageModal/>
            default:
                return null
        }
    }

    return (
        <>
            {selectModalPage()}
        </>
    )
}

const mapStateToProps = ({modalType}) => ({modalType})

export default connect(mapStateToProps,{setShowModal, setModalType, setResetPasswordModal, setMessageModal})(Modal)

import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import { useParams} from 'react-router-dom';
import { setAreaData, setContentHeight } from '../actions';
import SectionMeta from '../subcomponents/SectionMeta';
import SectionHousing from '../subcomponents/SectionHousing';
import SectionMap from '../subcomponents/SectionMap';
import SectionGettingAround from '../subcomponents/SectionGettingAround';
import SectionAffluence from '../subcomponents/SectionAffluence';
import SectionDiversity from '../subcomponents/SectionDiversity';
import SectionCrime from '../subcomponents/SectionCrime';
import SectionDemographics from '../subcomponents/SectionDemographics';
import SectionWorkers from '../subcomponents/SectionWorkers';
import SectionOther from '../subcomponents/SectionOther';
import { NavBuffer } from '../subcomponents/NavBarElements';
import { contentHeightBuffer } from '../constants';
import qcApi from '../apis';

const AreaBio = props => {

    const {contentHeight, pageViewId, clientCountry, fingerprint, user} = props
    const params = useParams()
    
    useEffect(() => {

        const screenContent = document.getElementById("areabio_content")
        props.setContentHeight(screenContent.offsetHeight + contentHeightBuffer)

        if(clientCountry && fingerprint) {
            props.setAreaData(params.areaid, pageViewId, clientCountry, fingerprint)
        }

        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "areabio"
            })
        }
        
    }, [params.areaid, contentHeight, clientCountry, fingerprint])

    return (
        <>
        <div className="ui container custom_areabio_container" id="areabio_content">
            <br/>
            <br/>
            <NavBuffer/>
            <SectionMeta/>
            <SectionMap/>
            <SectionHousing/>
            <SectionGettingAround/>
            <SectionAffluence/>
            <SectionDemographics/>
            <SectionDiversity/>
            <SectionCrime/>
            <SectionWorkers/>
            <SectionOther/>
        </div>
        </>
    )
}

const mapStateToProps = ({
    contentHeight, 
    pageViewId, 
    areaMetaData,
    clientCountry, 
    fingerprint,
    user
}) => ({
    contentHeight, 
    pageViewId, 
    areaMetaData,
    clientCountry, 
    fingerprint,
    user
})

export default connect(mapStateToProps, {setAreaData, setContentHeight})(AreaBio)
import React from 'react';
import { 
    Bar, 
    XAxis,
    YAxis,
    CartesianGrid,
    BarChart,
    LabelList,
    ResponsiveContainer,
    Tooltip 
} from 'recharts';
import { 
  formatNumToLocalString, 
  responsiveWaterfallChart, 
  xAxisLabels 
} from '../../functions';
import { chartColours } from '../../constants';

export const WaterfallChart = props => {
  const {data, screenWidth, labelCallback} = props
  const colours = [chartColours[1][0], chartColours[0][0]]
  const minYValue = (data[data.length - 1].value) * 0.8
  
  const {
    xFontSize, 
    layout, 
    xType, 
    yType, 
    xKey, 
    yKey,
    xTickFormatter,
    yTickFormatter,
    yCustomLabelMargin,
    xCustomLabelMargin,
    textAnchor
} = responsiveWaterfallChart(screenWidth)

  const relabeledData = data.map(item => {
    const {name} = item
    const fullName = labelCallback(name)
    const axisName = xAxisLabels(screenWidth, fullName)
    const newItem = {...item, name: axisName, fullName}
    return newItem
  })
  

  const BarLabels = props => {
    const { x, y, width, index, value } = props;
    const barColour = index === 0 || index === data.length - 1 
    ? "white"
    : colours[0]
    const barAlign = index === 0 || index === data.length - 1 
    ? -25
    : 10
    const xMargin = xCustomLabelMargin ? 100 : x + width / 2
    const yMargin = yCustomLabelMargin ? y + 16 : y - barAlign
    
    return (
        <text 
          x={xMargin} 
          y={yMargin} 
          fill={barColour} 
          textAnchor={textAnchor} 
          dominantBaseline="middle">
            {formatNumToLocalString(value)}
        </text>
    )
  }

  const CustomTooltip = (props) => {
    const {active, payload} = props
    
    if (active && payload && payload.length) {
      const label = payload[0].payload.fullName

        return (
        <div className="custom_tooltip" >
            <p className="label">{label}</p>
        </div>
        )}}

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={relabeledData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        layout={layout}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={xKey} 
          fontSize={xFontSize} 
          key={xKey}
          type={xType}
          tickFormatter={xTickFormatter}/>
        <YAxis 
          tickFormatter={yTickFormatter} 
          allowDataOverflow={true} 
          domain={[minYValue, "auto"]}
          type={yType}
          dataKey={yKey}/>
        <Tooltip content={<CustomTooltip/>}/>
        <Bar dataKey="delta" stackId="a" fill="#FFF" fillOpacity={0} />
        <Bar dataKey="value" stackId="a" fill={colours[0]} name="value">
          <LabelList dataKey="value" content={<BarLabels/>}/>
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
import axios from 'axios';

const serverUrl = "/api"

const qcApi = axios.create({
    baseURL: serverUrl,
    headers: {
        'Access-Control-Allow-Credentials' : 'true',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Access-Control-Allow-Headers, X-Requested-With, Accept, charset, boundary, Content-Length"
    }
})

export const ipGeolocationApi = axios.create({
    baseURL: "https://api.ipgeolocation.io/ipgeo",
    headers: {
        'content-type': 'application/json',
    }
})

export default qcApi
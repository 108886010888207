import React from 'react';
import { connect } from 'react-redux';
import MultiMarkers from '../MultiMarkers';
import { dummyPin } from '../../resources';

const MarkersNurseries = props => {
    const {nurseries, showMarkers, name} = props
    if(!showMarkers[name]) {return null}
    
    const locations = nurseries.map(item => {

        const tooltip = (
        <>
        <p>Type: {item.type}</p>
        <p>Address: {item.address}</p>
        <p>{item.places} places</p>
        </>
        )
        return ({
            coords: [item.lat, item.lon],
            popup: item.name,
            tooltip
            })
        });
    

    return(
        <MultiMarkers
            data={locations}
            icon={dummyPin}
        />
    )
}

const mapStateToProps = ({showMarkers, nurseries}) => {
    return ({showMarkers, nurseries})
}

export default connect(mapStateToProps, {})(MarkersNurseries)
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { InfoBanner } from '../elements/general';
import { BlueButton } from '../../subcomponents/elements/formElements';
import qcApi from '../../apis';
import history from '../../history';


const Sources = ({fingerprint, user}) => {

    useEffect(() => {
        
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "sources"
            })
        }
    }, [fingerprint])  

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="Data"
            subheading="Where our data and information is sourced"/>
            <div className="ui container">
            <br/>
            <br/>
            <div className="ui basic segment">
                <div className="ui small header item image_item">
                    Overview
                </div>
                <p>
                    The information contained on this website is derived from publicly available and commericial data sources. 
                </p>
                <p>
                    These sources may in turn be based or underpinned by further data sources and we have done our best to reference the original source where possible. However, we cannot guarantee that our referenced sources are always directly referenced from the original source and we are dependent on the accuracy of the reference of our immmediate source. 
                </p>
                <div className="ui small header item image_item">
                    Table of sources
                </div>
                <table className="ui celled padded table">
                <thead>
                    <tr>
                        <th>Data point</th>
                        <th>Description</th>
                        <th>Source reference</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><h5>Area descriptions</h5></td>
                        <td>
                            <p>
                                We have derived description and overview information for each electoral ward and postal district code from different sources
                            </p>
                            <p>
                                Electoral ward information has been sourced from Wikipedia articles where such information was available. The use of GPT-4 from Open AI was also used as well as a number of publicly available sources from local authority websites and forums. 
                            </p>
                            <p>
                                As GPT-4 is a language model rather than a static dataset, please by mindful that the article used may be be reproduced precisely when the exact query that requested the area description is re-executed. 
                            </p>
                        </td>
                        <td>
                            <p>
                                <a href="https://wikipedia.org">Wikipedia</a>
                            </p>
                            <p>
                                <a href="https://openai.com/product/gpt-4">GPT-4 by Open AI</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td><h5>House prices</h5></td>
                        <td>
                            <p>
                                House price data was sourced from the 'Price Paid Data' from the UK government statistical webservice. 
                            </p>
                            <p>
                                The data set used for house price and rental data was sourced from January 2023 data while historical house price data was sourced from from the period from January 1996 to December 2022.  
                            </p>
                            <p>
                                This data was sourced by postal district code level and rebased on a proportionate basis when sourcing calculating property data by electoral ward. 
                            </p>
                        </td>
                        <td>
                            <p>
                                <a href="https://www.gov.uk/government/statistical-data-sets/">UK Price Paid Data</a>
                            </p>
                        </td>
                    </tr>

                    
                    <tr>
                        <td><h5>Schools and nursery data</h5></td>
                        <td>
                            <p>
                                Schools data for England has been sourced from the Office of Standards in Education and provided by the government statistical portal. 
                            </p>
                            <p>
                                Schools data for Wales has been sourced from the Welsh data service in the 'My Local Schools Guide'. 
                            </p>
                            <p>
                                Nurseries data has been sourced from a number of sources such as Daynurseries and the Google Maps API. These sources are not open sourced and subject to usage charges
                            </p>
                        </td>
                        <td>
                            <p>
                                <a href="https://www.data.gov.uk/dataset">Office of Standards in Eduction (Ofsted)</a>
                            </p>
                            <p>
                                <a href="https://www.gov.wales/my-local-school-guide">My Local School Guide (Wales)</a>
                            </p>
                            <p>
                                <a href="https://daynurseries.co.uk">Day Nurseries</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td><h5>Crime data</h5></td>
                        <td>
                            <p>
                                Our analysis of crime is based on data provided by the England and Wales Police database of reported events from December 2020 to December 2022 to provide a robust sample of crime levels per ward and district code area. 
                            </p>
                            <p>
                                In this dataset, some events were not referable to a specific area code and as such has been excluded from the dataset although such instances represent less than 1% of total report crimes and should not materially impact the representation of crime levels for most areas. 
                            </p>
                        </td>
                        <td>
                            <p>
                                <a href="https://data.police.uk">England and Wales Police Data</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td><h5>Transport and electric vehicle charging data</h5></td>
                        <td>
                            <p>
                                Transit, transportation and car ownership data was sourced from the Office of Rail and Road, the National Public Transport register and Local Authority data as of January 2023. 
                            </p>
                            <p>
                                Additionally, car ownership data is based on the 2021 census data. 
                            </p>
                        </td>
                        <td>
                            <p>
                                <a href="https://www.data.gov.uk/dataset/">National Public Transport Access Nodes (NaPTAN)</a>
                            </p>
                            <p>
                                <a href="https://dataportal.orr.gov.uk">Office of Rail and Road</a>
                            </p>
                            <p>
                                <a href="https://maps.dft.gov.uk/ev-charging-map/index.html">Department for Transportation</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td><h5>Public Parks</h5></td>
                        <td>
                            <p>
                                Parks, public spaces, gardens and fields data is sourced from the Historic England and the Ordinance Survey Data Hub. 
                            </p>
                            <p>
                                Please note that both sources contains the same landmarks and may be duplicated in the map marker element. 
                            </p>
                        </td>
                        <td>
                            <p>
                                <a href="https://historicengland.org.uk/listing/what-is-designation/registered-parks-and-gardens/">Historic England - Registered Parks and Gardens</a>
                            </p>
                            <p>
                                <a href="https://osdatahub.os.uk">Ordinance Survey</a>
                            </p>
                        </td>
                    </tr>
                    
                    <tr>
                        <td><h5>Resident Statistical Data</h5></td>
                        <td>
                            <p>
                                All data on resident populations at electoral ward level is sourced from the 2021 official census and labour market statistics. 
                            </p>
                            <p>
                                These include:
                            </p>
                            <ul>
                                <li>Ethnicity</li>
                                <li>Languages</li>
                                <li>Demographics</li>
                                <li>Affluence</li>
                                <li>Commuting</li>
                                <li>Worker Profiles</li>
                                <li>Marital Status</li>
                                <li>Home Ownership</li>
                                <li>Resident Health</li>
                                <li>Age composition</li>
                            </ul>
                        </td>
                        <td>
                            <p>
                                <a href="https://www.nomisweb.co.uk/sources/census_2021">Official Census and Labour Market Statistics</a>
                            </p>
                        </td>
                    </tr>


                </tbody>
                </table>
                <br/>
                <BlueButton
                    label="Back to home"
                    onClick={() => history.push("/")}
                    icon="home icon"
                    className="icon"/>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = ({fingerprint, user}) => ({fingerprint, user})

export default connect(mapStateToProps)(Sources)
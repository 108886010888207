import React from 'react';
import { connect } from 'react-redux';
import MultiMarkers from '../MultiMarkers';
import { primaryIcon, secondaryIcon } from '../../resources';

const MarkersSchools = props => {
    
    const {schoolGroup, name, showMarkers, schools: {primary_schools, secondary_schools}} = props
    if (!primary_schools || !secondary_schools || !showMarkers[name]) {return null}
    
    const primaryLocations = primary_schools.map(item => {
        const pupils = item.pupils ? <p>Pupils: {item.pupils}</p> : null
        const gender = item.gender ? <p>Gender: {item.gender}</p> : null
        const rating = item.source === "England" && item.rating 
        ? <p>Rating: {item.rating}</p>
        : null

        const popup = (
            <>
            {gender}
            {pupils}
            {rating}
            <p>Type: {item.school_type}</p>
            </>
        )

        return ({
            coords: [item.lat, item.lon],
            tooltip: item.school_name,
            popup
        })
    })

    const secondaryLocations = secondary_schools.map(item => {

        const pupils = item.pupils ? <p>Pupils: {item.pupils}</p> : null
        const gender = item.gender ? <p>Gender: {item.gender}</p> : null
        const rating = () => {
            if(item.source === "England" && item.rating) {
                return <p>Rating: {item.rating}</p>
            } else if(item.source === "Wales" && item.rating.nine_point) {
                return (
                    <div>
                        Ratings
                        <ul>
                            <li>Nine point: {item.rating.nine_point}</li>
                            <li>Literacy: {item.rating.literacy}</li>
                            <li>Numeracy: {item.rating.numeracy}</li>
                            <li>Science: {item.rating.science}</li>
                            <li>Cert points: {item.rating.cert_points}</li>
                        </ul>
                    </div>
                )
            } else {
                return null
            }
        }

        const popup = (
            <>
                {gender}
                {pupils}
                <p>Type: {item.school_type}</p>
                {rating()}
            </>
        )
        return ({
            coords: [item.lat, item.lon],
            tooltip: item.school_name,
            popup
        })
    })

    const {value} = schoolGroup
    
    const renderPrimaryMarkers = value === "all" || value === "primary"
    ? <MultiMarkers data={primaryLocations} icon={primaryIcon}/>
    : null

    const renderSecondaryMarkers = value === "all" || value === "secondary"
    ? <MultiMarkers data={secondaryLocations} icon={secondaryIcon}/>
    : null

    return(
        <>
        {renderPrimaryMarkers}
        {renderSecondaryMarkers}
        </>
    )
}

const mapStateToProps = ({showMarkers, schools, schoolGroup}) => {
    return ({showMarkers, schools, schoolGroup})
}

export default connect(mapStateToProps, {})(MarkersSchools)

import React from 'react';
import { 
    Bar, 
    XAxis,
    YAxis,
    CartesianGrid,
    BarChart,
    ResponsiveContainer,
    Legend,
    Tooltip 
} from 'recharts';
import { 
  convertToPercentageDecimal, 
  convertToPercentage, 
} from '../../functions';
import { chartColours } from '../../constants';

export const StackedChart = props => {
  const {data, showFull} = props
  const colours = chartColours[1]
  const chartDimensions = {
    height: showFull ? "90%" : "80%",
    marginTop: showFull ? 60 : 80,
    marginBottom: showFull ? 0 : -20,
  }
  const {height, marginTop, marginBottom} = chartDimensions
  
  return (
      <ResponsiveContainer width="100%" height={height}>
          <BarChart 
            data={data} 
            margin={{top: marginTop, right: 30, left: 0, bottom: marginBottom}}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              {showFull ? <YAxis tickFormatter={convertToPercentage} domain={[0, 1]}/> : null}
              <Tooltip />
              <Legend align="center" />
              <Bar dataKey="numcars_none" stackId="a" fill={colours[0]} name="None" formatter={convertToPercentageDecimal}/>
              <Bar dataKey="numcars_one" stackId="a" fill={colours[1]} name="One" formatter={convertToPercentageDecimal}/>    
              <Bar dataKey="numcars_two" stackId="a" fill={colours[2]} name="Two" formatter={convertToPercentageDecimal}/>    
              <Bar dataKey="numcars_three" stackId="a" fill={colours[3]} name="Three +" formatter={convertToPercentageDecimal}/>    
          </BarChart>
      </ResponsiveContainer>
  )
}
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import qcApi from '../../apis';
import { InfoBanner } from '../elements/general';
import { BlueButton } from '../elements/formElements';
import { setContentHeight }from '../../actions';
import history from '../../history';


const Methodology = props => {

    const {fingerprint, user, screenHeight, contentHeight} = props
    const [containerHeight, setContainerHeight] = useState({})

    useEffect(() => {

        const screenContent = document.getElementById("method_page").offsetHeight
        props.setContentHeight(screenContent)
        
        if(fingerprint) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "method"
            })
        }
        
        if(contentHeight > screenHeight) {
            setContainerHeight({})
        } else {
            setContainerHeight({height: contentHeight + 200})
        }

    }, [fingerprint, contentHeight])  

    return (
        <div className="custom_top_padding">
            <InfoBanner 
            heading="Methodology"
            subheading="How we process the data you're seeing"/>
            <div className="ui container">
            <br/>
            <br/>
            <div className="ui basic segment" style={containerHeight} id="method_page">
                <div className="ui small header item image_item">
                    Overview
                </div>
                <p>
                    The scores and assessment on this website are based on raw data sourced from <span><a href="/sources">various data repositories.</a></span> 
                </p>
                <div className="ui small header item image_item">
                    Other methods of processing data
                </div>
                <table className="ui celled padded table">
                <thead>
                    <tr>
                        <th>Metric</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td><h5>Area scores</h5></td>
                        <td>
                        <p>
                            The assessments in the overview section of our area biographies are consistently based on a proprietary formula applied against the sourced raw data and indexed on relative basis to the rest of the local authority, region or country to provide a relative score. 
                        </p>
                        <p>
                            It is important to reiterate that these scores are expressed on a relative basis and are not intended to represent an absolute judgement for that area on a particular attribute. 
                        </p>
                        <p>     
                            For example, crime scores do not suggest that criminal activity is pervasive or absent in a particular area. Instead, it is merely a ranking of the levels of crime per 1,000 residents over the last two years relative to other areas in the country. 
                        </p>
                        </td>
                    </tr>

                    <tr>
                        <td><h5>Postal district and geography codes</h5></td>
                        <td>
                            <p>
                                Many of our datasets are represented by electoral ward level or by the <span><a href="https://www.data.gov.uk/dataset/c481f2d3-91fc-4767-ae10-2efdf6d58996/lower-layer-super-output-areas-lsoas">lower super output area (LSOA)</a></span>. 
                            </p>
                            <p>
                                Where such data was not recorded on a postal district level, calculations were applied that looked at each electoral ward and the proportions of that ward attributable to a postal district code on a geographic basis. 
                            </p>
                            <p>
                                Conversely, other datasets such as housing data is disclosed by postal code. As postal codes are not always exclusively referable to an electoral ward, there are instances where such data needs to be proportionately estimated to generate representative data for informational purposes.  
                            </p>
                            <p>
                                In the above cases, the statistics and information presented on such a basis represents a proportionate estimation rather than a precise statistic for that area definition
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td><h5>Qualification score</h5></td>
                        <td>
                            <p>
                                The 2021 Census details the level of qualification achieved by residents for each output area in England and Wales. With the advice geographers and urban researchers, we've processed this data to generate a score that represents the general 'qualification level' of a population. In conjunction with income levels, we've used these data points to support our a view of the level of affluence of a given area
                            </p>
                        </td>
                    </tr>

                </tbody>
                </table>
                <br/>
                <BlueButton
                    label="Back to home"
                    onClick={() => history.push("/")}
                    icon="home icon"
                    className="icon"/>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = ({
    fingerprint, 
    user, 
    screenHeight, 
    contentHeight
}) => ({
    fingerprint, 
    user, 
    screenHeight, 
    contentHeight
})

export default connect(mapStateToProps, {setContentHeight})(Methodology)
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { setNoData, setLoadingTimer } from '../actions';
import { mobileScreenWidth } from '../constants';
import { TextLoader } from './elements/general';
import UkMap from '../subcomponents/elements/UkMap';
import AreaMetrics from '../subcomponents/elements/MetricScores';

const SectionMeta = props => {
    
    const {
        loadingTimer,
        noData,
        screenWidth,
        areaMetrics,
        areaMetaData: {
            ward, 
            residents, 
            local_auth, 
            description,
            centrepoint,
            postcodes,
            district_code,
            local_authorities, 
        }} = props
    
    useEffect(() => {
        
        if(!ward && !district_code) {
            const timer = setTimeout(() => props.setNoData({meta: true}), 5000)
            props.setLoadingTimer(timer)
        }

        return (() => {
            props.setNoData({meta: false})
            clearTimeout(loadingTimer)
        })
    }, [ward, district_code])

    const renderNoResult = noData.meta 
    ? <div className="ui basic segment">
            <div className="ui medium header">
                Unable to find your page
            </div>
        </div>
    : <TextLoader/>

    if(!ward && !district_code) {
        return renderNoResult
    }
    
    const columnWidths = screenWidth > mobileScreenWidth 
    ? ["six", "ten"] 
    : ["sixteen", "sixteen"]

    const renderPostcodes = () => postcodes.map((postcode, index) => {
        if(index + 1 === postcodes.length) {
            return <span key={index}>{`${postcode}`}</span>
        } else {
            return <span key={index}>{`${postcode}, `}</span>
        }
    })

    const renderLocalAuthorities = () => local_authorities.map((item, index) => {
        if(index + 1 === local_authorities.length) {
            return <span key={index}>{`${item}`}</span>
        } else {
            return <span key={index}>{`${item}, `}</span>
        }
    })    

    const heading = ward ? `${ward} (${local_auth})` : district_code
    
    const subheadingOne = ward 
    ? <p className="ui small header">Postcode(s): {renderPostcodes()}</p>  
    : <p className="ui small header">{renderLocalAuthorities()}</p>
    
    return(
        <div className="ui segment">
            <div className="ui huge header">
                {heading}
            </div>
            <br/>
            <div className="ui grid">
                <div className={`${columnWidths[1]} wide column`}>
                    {subheadingOne}
                    <p className="ui small header">
                        {`Population: ${residents.toLocaleString()}`}
                    </p>
                    <p className="paragraph">{description}</p>
                </div>
                <div className={`${columnWidths[0]} wide column`}>
                    <UkMap centre={centrepoint}/>
                </div>
                <div className="sixteen wide column"><hr/></div>
                <AreaMetrics 
                    areaMetrics={areaMetrics} 
                    screenWidth={screenWidth}
                    ratingExemption={["score_age"]}/>
            </div>
            <br/>
        </div>
    )
}

const mapStateToProps = ({areaMetrics, areaMetaData, screenWidth, loadingTimer, noData}) => ({areaMetrics, areaMetaData, screenWidth, loadingTimer, noData})

export default connect(mapStateToProps, {
    setLoadingTimer, 
    setNoData
})(SectionMeta)
import React, {useEffect} from 'react';
import {Input, Form} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setSearchTerm, setSearchResults, setContentHeight } from '../actions';
import { mobileScreenWidth } from '../constants';
import { v4 as uuid } from 'uuid';
import history from '../history';
import { YellowButton } from '../subcomponents/elements/formElements';
import qcApi from '../apis';

const Home = props => {

    const {fingerprint, screenHeight, searchTerm, user, searchResponse: {data_status}} = props
    const containerMargin = screenHeight > mobileScreenWidth
    ? screenHeight * 0.9
    : screenHeight * 1.25

    const submitSearchQuery = () => {
        if(searchTerm) {
            const queryId = uuid()
            props.setSearchResults(searchTerm, queryId, fingerprint)
            history.push(`/search/${searchTerm}`)
        } else {
            return
        }

    }
    
    useEffect(() => {

        props.setSearchTerm("")
        const screenContent = document.getElementById("home_content").offsetHeight
        props.setContentHeight(screenContent)

        if (fingerprint && user.userId) {
            qcApi.post("/page-view", {
                client: fingerprint,
                user_id: user.userId,
                page: "home"
            })
        }
        
    }, [data_status, fingerprint])
    
    return (
        <div 
            className="custom_home_container" 
            style={{height: screenHeight}}
            id="home_content"
            >
        <div className="ui container" style={{height: containerMargin}}>
            <div className="ui very padded center aligned basic segment custom_vertical_center" >
                <div className="ui center aligned custom_home_tagline">
                    {screenHeight > 400
                    ? "Giving you what the real estate agent left out"
                    : null}
                </div>
                <Form onSubmit={submitSearchQuery}>
                    <div className="field">
                        <div className="ui icon fluid input">
                            <Input 
                                className="prompt" 
                                type="text" 
                                placeholder="Search area or postcode (e.g. 'Vauxhall' or 'N1' or 'SW4 0AA'"
                                value={props.searchTerm}
                                onChange={e => props.setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Form>
                <br/>
                <br/>
                <YellowButton
                    label="Search this neighbourhood"
                    onClick={submitSearchQuery}
                    icon="search icon"
                    className="icon"/>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = ({ 
    searchTerm, 
    searchResponse, 
    screenHeight,
    fingerprint,
    user
}) => ({ 
    searchTerm, 
    searchResponse, 
    screenHeight,
    fingerprint,
    user
})

export default connect(mapStateToProps,{
    setSearchTerm, 
    setSearchResults,
    setContentHeight
})(Home)
import { 
    SET_VIEWPORT, 
    SET_SHOW_MOBILE_MENU,
    SET_LOADING_TIMER,
    SET_NO_RESULTS,
    SET_CONTENT_HEIGHT, 
    SET_AREA_DATA,
    SET_AREA_VIEW_ID,
    SET_FINGERPRINT,
    SET_CLIENT_COUNTRY
 } from '../actions/types';

export const fingerprintReducer = (state=null, action) => {
    switch(action.type) {
        case SET_FINGERPRINT:
            return action.payload;
        default:
            return state
    }
}

export const clientCountryReducer = (state=null, action) => {
    switch(action.type) {
        case SET_CLIENT_COUNTRY:
            return action.payload;
        default:
            return state
    }
}

export const screenWidthReducer = (state=[], action) => {
    switch(action.type) {
        case SET_VIEWPORT:
            return action.payload[0];
        default:
            return state;
    }
}

export const screenHeightReducer = (state=[], action) => {
    switch(action.type) {
        case SET_VIEWPORT:
            return action.payload[1];
        default:
            return state;
    }
}

export const showMobileMenuReducer = (state=false, action) => {
    switch(action.type){
        case SET_SHOW_MOBILE_MENU:
            return action.payload;
        default:
            return state;
}}

export const loadingTimerReducer = (state=null, action) => {
    switch(action.type){
        case SET_LOADING_TIMER:
            return action.payload;
        default:
            return state;
}}

export const noDataReducer = (state={}, action) => {
    switch(action.type){
        case SET_NO_RESULTS:
            return {...state, ...action.payload};
        default:
            return state;
}}

export const contentHeightReducer = (state=0, action) => {
    switch(action.type){
        case SET_CONTENT_HEIGHT:
            return action.payload;
        default:
            return state;
}}

export const pageViewIdReducer = (state=null, action) => {
    switch(action.type) {
        case SET_AREA_DATA:
            return action.payload.view_id;
        case SET_AREA_VIEW_ID:
            return action.payload
        default:
            return state
    }
}
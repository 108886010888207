import { 
    SET_AREA_DATA, 
    SET_SHOW_DETAILS, 
    SET_HOUSING_DATA, 
    SET_NURSERIES_DATA,
    SET_SHOW_MARKERS,
    SET_PARKS_DATA,
    SET_SCHOOLS_DATA,
    SET_SCHOOL_GROUP,
    SET_BUS_STOPS,
    SET_TRAIN_STATIONS,
    SET_CHARGER_STATIONS,
    SET_COMMUTING_DATA, 
    SET_AFFLUENCE_DATA,
    SET_DEMOGRAPHICS_DATA,
    SET_DIVERSITY_DATA,
    SET_TENURE_AND_HEALTH_DATA,
    SET_WORKER_DATA,
    SET_CRIME_DATA
} from '../actions/types';
import { sections } from '../constants';

export const areaMetaDataReducer = (state={}, action) => {
    switch(action.type) {
        case SET_AREA_DATA:
            return action.payload.meta;
        default:
            return state;
    }
}

export const areaMetricsReducer = (state={}, action) => {
    switch(action.type) {
        case SET_AREA_DATA:
            return action.payload.metrics;
        default:
            return state;
    }
}

export const showSectionReducer = (state=sections, action) => {
    switch(action.type) {
        case SET_SHOW_DETAILS:
            return action.payload;
        default:
            return state
    }
}

export const houseDataReducer = (state={}, action) => {
    switch(action.type) {
        case SET_HOUSING_DATA:
            return action.payload;
        default:
            return state
    }
}

export const nurseriesDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_NURSERIES_DATA:
            return action.payload;
        default:
            return state
    }
}

export const showMarkersReducer = (state={}, action) => {
    switch(action.type) {
        case SET_SHOW_MARKERS:
            return action.payload;
        default:
            return state
    }
}

export const parksDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_PARKS_DATA:
            return action.payload;
        default:
            return state
    }
}

export const schoolsDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_SCHOOLS_DATA:
            return action.payload;
        default:
            return state
    }
}

export const schoolGroupReducer = (state={primary: true, secondary: true, value: "all"}, action) => {
    switch(action.type) {
        case SET_SCHOOL_GROUP:
            return action.payload
        default:
            return state
    }
}

export const busStopsReducer = (state=[], action) => {
    switch(action.type) {
        case SET_BUS_STOPS:
            return action.payload;
        default:
            return state
    }
}

export const trainStationsReducer = (state=[], action) => {
    switch(action.type) {
        case SET_TRAIN_STATIONS:
            return action.payload;
        default:
            return state
    }
}

export const chargersReducer = (state=[], action) => {
    switch(action.type) {
        case SET_CHARGER_STATIONS:
            return action.payload;
        default:
            return state
    }
}

export const commutingDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_COMMUTING_DATA:
            return action.payload;
        default:
            return state
    }
}

export const affluenceDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_AFFLUENCE_DATA:
            return action.payload;
        default:
            return state
    }
}

export const demographicsDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_DEMOGRAPHICS_DATA:
            return action.payload;
        default:
            return state
    }
}

export const diversityDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_DIVERSITY_DATA:
            return action.payload;
        default:
            return state
    }
}

export const tenureAndHealthDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_TENURE_AND_HEALTH_DATA:
            return action.payload;
        default:
            return state
    }
}

export const WorkerDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_WORKER_DATA:
            return action.payload;
        default:
            return state
    }
}
export const CrimeDataReducer = (state=[], action) => {
    switch(action.type) {
        case SET_CRIME_DATA:
            return action.payload;
        default:
            return state
    }
}

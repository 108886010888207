import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { mobileScreenWidth } from '../constants';
import { setShowDetails, setHousingData, setNoData, setLoadingTimer } from '../actions';
import { transformDatasetToThousands, roundDatasetToInteger } from '../functions';
import { HouseBarChart, HouseLineChart} from './charts/housingCharts';
import { ChartLoader, NodataContainer } from './elements/general'


const HousingCharts = props => {

    const {
        ward, 
        showFull, 
        labels, 
        houseData: {house_prices, historic_prices}
    } = props
    if(!house_prices || !historic_prices) {return <div>Loading</div>}
    const chartHeight = showFull ? 400 : 250
    const noHouseData = !house_prices.average_house_price.district && !house_prices.average_house_price.ward
    const noHistoricData = !historic_prices.slice(-1)[0].ward && !historic_prices.slice(-1)[0].district
    
    const {
        average_house_price, 
        average_price_1_bedroom, 
        average_price_2_bedroom, 
        average_price_3_bedroom, 
        average_price_4_bedroom,
        average_rental_price,
        average_rental_1_bedroom,
        average_rental_2_bedroom,
        average_rental_3_bedroom,
        average_rental_4_bedroom
    } = house_prices
    
    const housePriceData = [
        {...average_house_price, name: "Average"},
        {...average_price_1_bedroom, name: "1 bed"},
        {...average_price_2_bedroom, name: "2 bed"},
        {...average_price_3_bedroom, name: "3 bed"},
        {...average_price_4_bedroom, name: "4 bed"}
    ]
    
    const houseRentalData = [
        {...average_rental_price, name: "Average"},
        {...average_rental_1_bedroom, name: "1 bed"},
        {...average_rental_2_bedroom, name: "2 bed"},
        {...average_rental_3_bedroom, name: "3 bed"},
        {...average_rental_4_bedroom, name: "4 bed"}
    ]
    return (
        <>
        {noHouseData 
        ? <NodataContainer text="No house price data" icon="warehouse"/>
        : <HouseBarChart 
            data={transformDatasetToThousands(housePriceData)} 
            chartName="House prices (000s)"
            labels={labels}
            showLegend
            showFull={showFull}
            chartHeight={chartHeight}
            ward={ward}
            />}
        {noHouseData
        ? <NodataContainer text="No rental data" icon="warehouse"/>
        : <HouseBarChart 
            data={roundDatasetToInteger(houseRentalData)} 
            chartName="Rental prices (PCM)"
            labels={labels}
            showLegend={false}
            showFull={showFull}
            chartHeight={chartHeight}
            ward={ward}
            />}
        {noHistoricData
        ? <NodataContainer text="No historic_price data" icon="chart bar"/>
        : <HouseLineChart
            data={roundDatasetToInteger(historic_prices)}
            labels={labels}
            chartName="Historic prices (000s): 1995-2022"
            chartHeight={chartHeight}
            showFull={showFull}
            ward={ward}
        />}

        </>
    )
}

const SectionHousing = props => {

    const {
        screenWidth, 
        showSection, 
        areaMetaData, 
        houseData, 
        loadingTimer, 
        pageViewId
    } = props
    const {
        ward, 
        local_auth, 
        region, geocode, 
        district_code, 
        local_authorities,
        la_geocode,
        id,
        country
    } = areaMetaData
    
    const focused = showSection.housing
    const {data_status} = houseData
    const locationId = ward ? id : district_code
    const iconSize = screenWidth > mobileScreenWidth ? "big" : null
    const showFull = screenWidth > mobileScreenWidth
    const icon = focused ? "minus" : "plus"

    useEffect(() => {
        if(!ward && !district_code) {
            const timer = setTimeout(() => props.setNoData({housing: true}), 5000)
            props.setLoadingTimer(timer)
        }

        return (() => {
            props.setNoData({housing: false})
            clearTimeout(loadingTimer)
        })
    }, [ward, district_code])
    if(!ward && !district_code) {
        return (
        <div className="ui basic segment">
            <ChartLoader/>
        </div>
        )
    }
    
    const locationAuth = ward ? la_geocode : local_authorities[0]
    const locationAuthName = ward ? local_auth : local_authorities[0]
    const countryName = ward ? geocode.charAt(0) === "E" ? "England" : "Wales" : country[0]
    const regionName = ward ? region : region[0]
    
    const locations = {
        ward: ward, 
        local_authority: locationAuthName, 
        region: regionName, 
        country: countryName,
        district: district_code
    }
    
    const unhideContent = () => {
        if(focused) {
            props.setShowDetails({housing: false})
        } else {
            props.setShowDetails({housing: true}, pageViewId)
            if(!data_status) {props.setHousingData(locationId, locationAuth, ward)};
        }
    }
        

    return(
        <div className="ui red segment">
            <div className="ui grid">
                <div className="fourteen wide column middle aligned">
                    <div className="ui medium header">Housing affordability</div>
                </div>
                <div className="two wide column right aligned">
                    <i className={`${icon} circle ${iconSize} icon`} onClick={unhideContent}/>
                </div>
                {focused 
                ? <HousingCharts 
                    houseData={houseData} 
                    labels={locations} 
                    showFull={showFull}
                    ward={ward}
                    /> 
                : null}
            </div>
        </div>
    )
}

const mapStateToProps = ({
    screenWidth, 
    showSection, 
    areaMetaData, 
    houseData,
    pageViewId,
    noData,
    loadingTimer
}) => ({
    screenWidth, 
    showSection, 
    areaMetaData, 
    houseData, 
    pageViewId, 
    loadingTimer, 
    noData
})

export default connect(mapStateToProps, {
    setShowDetails, 
    setHousingData,
    setNoData,
    setLoadingTimer
})(SectionHousing)

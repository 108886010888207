import React, {useState} from 'react';
import {Form, Field} from 'react-final-form';
import { Modal, Header, Form as FormComponent } from 'semantic-ui-react';
import { connect } from 'react-redux';
import history from '../history';
import RequestPasswordModal from '../modals/RequestPassword';
import { 
    setShowModal, 
    setModalType, 
    setLogStatus, 
    setLoginMessage, 
    setSignupMessage, 
    setRequestPasswordModal, 
    setTemporaryCredentials, 
    setResetPasswordModal, 
    setModalHeader, setCheckAuth } from '../actions';
import { validEmail, validUsername, validPassword } from '../functions';
import { 
    onGoogleAuth, 
    onSignup, 
    onLogin, 
    clearCookies, 
    setAccessTokenCookie, 
    checkDuplicateKeys } from '../functions/auth';
import { TextField, PasswordField, SubmitButton } from '../subcomponents/elements/formElements';

let AccountTypeSelection = props => {

    const {showModal, modalType, screenWidth, loginMessage, signupMessage, requestPasswordModal, screenHeight} = props
    const [uniqueValue, setUniqueValue] = useState({username: true, email: true});
    
    let timer
    const disabled = !uniqueValue.username || !uniqueValue.email ? "disabled" : null

    const showFull = screenWidth > 767
    const fullDimensions = {width: "eight", break: null}
    const partialDimensions = {width: "sixteen", break: <br/>}
    const dimensions = showFull ? fullDimensions : partialDimensions
    
    const headerText = modalType === "login" ? "Log in" : "Sign up"
    const welcomeMessage = modalType === "login" ? loginMessage : signupMessage
    const prefix = modalType === "login" ? "Log in" : "Sign up"

    const resetPasswordElement = modalType === "signup"
    ? <>{dimensions.break}</>
    : <div className="ui grid" >
            <div 
                className={`${dimensions.width} wide column clickable_text`} 
                style={{margin: "auto"}}>
                <p style={{textAlign: "right"}} 
                onClick={() => props.setRequestPasswordModal(true)}>
                    Forgot your password?
                </p>  
            </div>
        </div>

    const footer = modalType === "login"
    ? "No account? Sign up here"
    : "Registered? Log in here"

    const usernameElement = modalType === "login"
    ? null
    : <> <div className="fields">
        <Field 
            name="username" 
            component={TextField} 
            label="Username" autoComplete="false" 
            classTag={`${dimensions.width} wide`}
            duplicate={!uniqueValue.username}
            validate={value => checkForDuplicates(value, "username")}
            validateFields={["username"]}/>
    </div>
    {dimensions.break}
    </>

    const onHideClick = () => {
        props.setShowModal(false)
        props.setModalType(null)
        history.push("/")
    }

    const signinOrLoginSwitch = () => {
        if(modalType === "login") {
            props.setLoginMessage("Please sign up")
            history.push("/signup")
        } else {
            props.setLoginMessage("Welcome back")
            history.push("/login")
        }
    }
    
    const checkForDuplicates = async (value, field) => {
        if (!value || modalType !== "signup") { return }
        
        const duration = uniqueValue[field] ? 3000 : 500
        
        clearTimeout(timer)
        timer = setTimeout(async () => {
            const data = await checkDuplicateKeys(value, field)
            
            const uniqueObject = {}
            uniqueObject[field] = data.unique
            if(data.unique !== uniqueValue[field]) {
                setUniqueValue({...uniqueValue, ...uniqueObject})
            }
        }, duration)
    }
    
    const onFormSubmit = formValues => {

        if(!uniqueValue.username || !uniqueValue.email) {
            return
        } else if(modalType === "signup") {

            props.setCheckAuth(false)
            onSignup(formValues).then(res => {
                const {access_token, logged_in, existing_user} = res
                if(existing_user) {
                    props.setLoginMessage("It looks likes we've met. Please log in")
                    history.push("/login")
                } else {
                    props.setLogStatus(logged_in)
                    if(access_token) {
                        setAccessTokenCookie(access_token)
                        history.push("/")
                    } else {
                        clearCookies()
                        history.push("/signup")
                    }
                }
            })
        } else if(modalType === "login") {
            props.setCheckAuth(false)
            onLogin(formValues).then(res => {
            
            const {access_token, existing_user, google_account, reset, email} = res
            if(!existing_user) {
                props.setSignupMessage("We haven't met. Please sign up")
                history.push("/signup")
            } else if(google_account) {
                props.setLoginMessage("Please log in using Google sign in")
                history.push("/login")
            } else if(reset) {
                const credentials = {email, password: formValues.password}
                props.setTemporaryCredentials(credentials)
                props.setModalHeader("Please reset your password")
                props.setModalType("reset")
                props.setShowModal(false)
                history.push("/reset")
            } else if(access_token) {
                props.setLogStatus(true)
                setAccessTokenCookie(access_token)
                history.push("/")
            } else {
                clearCookies()
                props.setLogStatus(false)
                props.setLoginMessage("Incorrect password - please try again")
                history.push("/signup")
                history.push("/login")

            }
        })}   
    }

    const onGoogleClick = () => {
        props.setCheckAuth(false)
        onGoogleAuth()
    }
    
    const validate = formValues => {

        const errors = {}
        
        if(modalType === "signup" && !validUsername.test(formValues.username)) {
            errors.username = "You must enter a valid username"
        }
        if(!validEmail.test(formValues.email) && formValues.email) {
            errors.email = "You must enter a valid email address"
        }
        if(modalType === "signup" && !validPassword.test(formValues.password)) {
            errors.password = "Your password must be a minimum eight characters, at least one letter, one number and one special character"
        }
        return errors
    }
    
    return(
        <>
        <div className="custom_home_container" style={{height: screenHeight, width: screenWidth}}/>
        <Modal
            closeIcon
            centered
            onClose={onHideClick}
            closeOnEscape={!requestPasswordModal}
            closeOnDimmerClick={!requestPasswordModal}
            open={showModal}>
            <Header>{headerText}</Header>
            <Modal.Content>
                    <Header textAlign="center">{welcomeMessage}</Header>
                    <Form 
                        validate={validate} 
                        validateOnBlur
                        onSubmit={values => onFormSubmit(values)}
                        render={({handleSubmit, pristine, submitting, form}) => (
                            <FormComponent onSubmit={handleSubmit}>
                                <br/>
                                {usernameElement}
                                <div className="fields">
                                    <Field 
                                        name="email" 
                                        component={TextField} 
                                        label="Email" autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}
                                        validate={value => checkForDuplicates(value, "email")}
                                        validateFields={["email"]}
                                        duplicate={!uniqueValue.email}/>
                                </div>
                                {dimensions.break}
                                <div className="fields">
                                    <Field 
                                        name="password" 
                                        component={PasswordField} 
                                        label="Password" 
                                        autoComplete="false" 
                                        classTag={`${dimensions.width} wide`}/>
                                </div>
                                {resetPasswordElement}
                                <div className="ui grid" >
                                    <div 
                                        className={`${dimensions.width} wide column`} 
                                        style={{margin: "auto"}}>
                                        <SubmitButton
                                            classTag={`ui primary ${disabled} fluid button`}
                                            buttonText={prefix}
                                            disabled={submitting || pristine}
                                            onClick={() => form.reset}
                                            />
                                    </div>
                                </div>
                            </FormComponent>
                        )}/>
                    <br/>
                    <hr/>
                    <br/>
                    <div className="ui grid" >
                        <div 
                            className={`${dimensions.width} wide column`}
                            style={{margin: "auto"}}>
                            <button 
                                className="ui google plus fluid button"
                                onClick={onGoogleClick}>
                                <i className="google icon"/>
                                {`${prefix} with Google`}
                            </button>  
                        </div>
                    </div>
                    <br/>
                    <div className="ui grid" >
                        <div 
                            className={`${dimensions.width} wide column ui tiny header clickable_text`}
                            style={{margin: "auto", textAlign: "center"}}
                            onClick={signinOrLoginSwitch}>
                            {footer}
                        </div>
                    </div>
            </Modal.Content>
            <RequestPasswordModal/>
        </Modal>
        </>
    )
}

const mapStateToProps = ({
    showModal, 
    modalType, 
    screenWidth, 
    screenHeight, 
    loginMessage, 
    signupMessage, 
    showRequestPasswordModal, 
    showResetPasswordModal
}) => ({
    showModal, 
    modalType, 
    screenWidth, 
    loginMessage, 
    signupMessage, 
    showRequestPasswordModal, 
    showResetPasswordModal, 
    screenHeight
})

AccountTypeSelection = connect(mapStateToProps, {
    setShowModal,
    setModalType,
    setLogStatus,
    setLoginMessage,
    setSignupMessage,
    setRequestPasswordModal,
    setTemporaryCredentials,
    setResetPasswordModal,
    setModalHeader,
    setCheckAuth
})(AccountTypeSelection)

export default AccountTypeSelection
import React from 'react';
import { 
    ComposedChart,
    Line,
    Bar, 
    XAxis,
    YAxis,
    ResponsiveContainer,
    Legend,
    Tooltip 
} from 'recharts';
import { 
  convertToPercentageDecimal, 
  responsiveBarChart,
  xAxisLabels 
} from '../../functions';
import { chartColours } from '../../constants';

export const MixedChart = props => {
  const {
    screenWidth, 
    data, 
    locations: {ward, district, region, country}, 
    labelCallback
  } = props

  const locationName = ward ? ward : district
  const colours = [chartColours[1][0], chartColours[1][2], chartColours[1][3]]

  const relabeledData = data.map(entry => {
    const label = xAxisLabels(screenWidth, labelCallback(entry.subject))
    const fullName = labelCallback(entry.subject)
    return {...entry, name: label, fullName}
  })

  const CustomTooltip = (props) => {
    const {active, payload} = props
    
    if (active && payload && payload.length) {
      const label = payload[0].payload.fullName
      const wardValue = convertToPercentageDecimal(payload[0].payload[locationName])
      const comparatorOne = convertToPercentageDecimal(payload[0].payload[region])
      const comparatorTwo = convertToPercentageDecimal(payload[0].payload[country])
      
        return (
        <div className="custom_tooltip" >
            <p className="label">{label}</p>
            <p className="label">{locationName}: {wardValue}</p>
            <p className="label">{region}: {comparatorOne}</p>
            <p className="label">{country}: {comparatorTwo}</p>
        </div>
        )}}
    
    const {
      xFontSize, 
      layout, 
      xType, 
      yType, 
      xKey, 
      yKey,
      xTickFormatter,
      yTickFormatter,
  } = responsiveBarChart(screenWidth, convertToPercentageDecimal)
  
  return(
      <ResponsiveContainer width="100%" height="80%">
          <ComposedChart 
            data={relabeledData} 
            margin={{top: 50, right: 30, left: 20, bottom: 10}}
            layout={layout}>
              <XAxis 
                dataKey={xKey} 
                tick={{fontSize: xFontSize}} 
                type={xType}
                tickFormatter={xTickFormatter}/>
              <YAxis 
                tickFormatter={yTickFormatter}
                type={yType}
                dataKey={yKey}/>
              <Tooltip content={<CustomTooltip/>}/>
              <Legend />  
              <Bar 
                dataKey={locationName} 
                stackId="a" 
                fill={colours[0]} 
                name={locationName} 
                label={{formatter: convertToPercentageDecimal, fill: "white"}}/>
              <Line 
                type="monotone" 
                dataKey={region} 
                stroke={colours[1]}
                strokeWidth={3} 
                name={region} 
                activeDot={{ r: 8 }} 
                dot={false} />  
              <Line 
                type="monotone" 
                dataKey={country} 
                stroke={colours[2]}
                strokeWidth={3} 
                name={country} 
                activeDot={{ r: 8 }} 
                dot={false} />  
          </ComposedChart>
      </ResponsiveContainer>
  )
}